// @flow

import type {Adress} from "../models/Adress";
import type {OrderItemsResponse} from "../models/Order";
import {OrderState} from "../models/Order";
import {CHECKOUT_PAGE_STATUS} from "../components/checkout/CheckoutPage";
import {roundAndScale} from "../helper/mathUtils";
import {createSlice} from "@reduxjs/toolkit";
import {couponErrorChecked, couponReset, couponSuccessChecked} from "./couponReducer";
import {toCartAdded} from "./cartReducer";
import RootNodeProvider from "../helper/RootNodeProvider";
import type { DecodedEmbeddedSps } from "./couponReducer";
import StringUtils from "../helper/stringUtils";
import {CustomerResponse} from "../api/CouponApi";
import type {HpmPrice} from "../helper/currency";

export type OrderReducerState = {
	slyCustomerData: ?CustomerResponse ;
	adress: Adress;
	order: OrderItemsResponse,
	orderCreated: boolean,
	showOrderFinishPage: boolean,
	currentPage: number,
	orderItemsPriceSum: HpmPrice,
	orderItemsPriceSumWithShipping: { cost: number, currency: string },
	totalBillingAmount: HpmPrice,
	orderItemsWithFullPriceSum: HpmPrice,
	shippingCosts: HpmPrice,
	maxPage: number,
	minPage: number,
	paymentSuccessful: boolean,
	isLoading: boolean,
	payment: {
		paymentProvider: string,
		paymentReference: string
	},
	orderState: OrderState
};

let decodedEmbeddedSps: ?DecodedEmbeddedSps = null;

const getInitialState: () => OrderReducerState = () => {
	if (RootNodeProvider.getRootNode() && RootNodeProvider.getRootNode().dataset) {
		const embeddedSps = RootNodeProvider.getRootNode().dataset.embedSps ? RootNodeProvider.getRootNode().dataset.embedSps : null;
		if (embeddedSps) {
			decodedEmbeddedSps = StringUtils.decodeBase64Json(embeddedSps);
		}
	}

	return {
		adress: {
			email: decodedEmbeddedSps ? decodedEmbeddedSps.Email : "",
				city: decodedEmbeddedSps ? decodedEmbeddedSps.Ort : "",
				countryCode: "",
				familyname: decodedEmbeddedSps ? decodedEmbeddedSps.Name : "",
				name: decodedEmbeddedSps ? decodedEmbeddedSps.Vorname : "",
				phone: "",
				vin: "",
				comment: "",
				street: decodedEmbeddedSps ? decodedEmbeddedSps.Strasse : "",
				postalCode: decodedEmbeddedSps ? decodedEmbeddedSps.PLZ : "",
				shippingAdress: false,
				shippingCity: "",
				shippingFamilyname: "",
				shippingName: "",
				shippingPostalCode: "",
				shippingStreet: "",
				shippingCountryCode: "",
		},
		order: null,
			orderCreated: false,
		showOrderFinishPage: false,
		currentPage: 0,
		maxPage: 0,
		minPage: 0,
		orderItemsPriceSum: null,
		totalBillingAmount: null,
		orderItemsWithFullPriceSum: null,
		shippingCosts: null,
		orderItemsPriceSumWithShipping: null,
		cartVerificationError: false,
		paymentSuccessful: false,
		isLoading: false,
		payment: {
		paymentProvider: null,
			paymentReference: null
	},
		orderState: OrderState.UNDEFINE,
		slyCustomerData: null,
	};
};

const orderSlice = createSlice({
	name: "order",
	initialState: getInitialState(),
	reducers: {
		slyCustomerDataSet(state, action) {
			state.slyCustomerData = action.payload;
			state.adress = {
				...state.adress,
				email: action.payload.email,
				city: action.payload.city,
				familyname: action.payload.lastName,
				name: action.payload.firstName,
				street: action.payload.street,
				postalCode: action.payload.postCode,
			};
		},
		cartAndCouponSuccessChecked(state, action) {
			state.totalBillingAmount = action.payload.totalBillingAmount;
			state.orderItemsPriceSumWithShipping = action.payload.orderItemsPriceSumWithShipping;
			state.orderItemsPriceSum = action.payload.orderItemsPriceSum;
			state.orderItemsWithFullPriceSum = action.payload.orderItemsWithFullPriceSum;
			state.shippingCosts = action.payload.shippingCosts;
			state.isLoading = false;
		},
		checkoutPageStepSet(state, action) {
			state.currentPage = action.payload;
			state.isLoading = false;
			state.maxPage = action.payload;
		},
		maximumCheckoutPageStepSet(state, action) {
			state.maxPage = action.payload;
		},
		paymentSet(state, action) {
			state.payment = action.payload;
			state.isLoading = false;
		},
		addressSet(state, action) {
			state.adress = action.payload;
		},
		orderDetailsCleared(state) {
			state.adress = getInitialState().adress;
			state.payment = getInitialState().payment;
			state.paymentDetails = null;
			state.orderCreated = false;
			state.order = null;
			state.showOrderFinishPage = false;
			state.orderState = null;
		},
		shopDonePageClosed(state) {
			state.showOrderFinishPage = false;
			state.currentPage = 0;
			state.maxPage = 0;
		},
		orderSuccessCreated(state, action) {
			state.orderCreated = true;
			state.order = action.payload;
			state.isLoading = false;
		},
		cartRequestChecked(state) {
			state.cartVerificationError = false;
			state.orderitemsPriceSum = null;
			state.orderItemsPriceSumWithShipping = null;
			state.totalBillingAmount = null;
			state.orderItemsWithFullPriceSum = null;
			state.shippingCosts = null;
			state.cartVerificationError = false;
			state.isLoading = true;
		},
		cartSuccessChecked(state, action) {
			state.totalBillingAmount = action.payload.totalBillingAmount;
			state.orderItemsPriceSumWithShipping = action.payload.orderItemsPriceSumWithShipping;
			state.orderItemsPriceSum = action.payload.orderItemsPriceSum;
			state.orderItemsWithFullPriceSum = action.payload.orderItemsWithFullPriceSum;
			state.shippingCosts = action.payload.shippingCosts;
			state.isLoading = false;
		},
		cartFailureChecked(state) {
			state.cartVerificationError = true;
			state.currentPage = 0;
			state.maxPage = 0;
			state.isLoading = false;
		},
		expressPaymentInitiated(state) {
			state.isLoading = true;
			state.minPage = CHECKOUT_PAGE_STATUS.OVERVIEW;
		},
		paymentInitiated(state) {
			state.isLoading = true;
		},
		paymentSuccess(state) {
			state.paymentSuccessful = true;
			state.isLoading = false;
		},
		paymentSuccessVerified(state) {
			state.paymentVerified = true;
			state.showOrderFinishPage = true;
			state.isLoading = false;
		},
		expressOrderSuccessCreated(state) {
			state.paymentVerified = true;
			state.showOrderFinishPage = true;
			state.isLoading = false;
			state.orderState = OrderState.PROCESSING;
		},
		orderFailureCreated(state) {
			state.maxPage = 0;
			state.currentPage = 0;
		},
		paymentFailureVerified(state) {
			state.maxPage = 0;
			state.currentPage = 0;
		},
		expressOrderErrorCreated(state) {
			state.maxPage = 0;
			state.currentPage = 0;
		},
		orderStateSet(state, action) {
			state.orderState = action.payload.authorizationState;
		},
		orderStateReset(state) {
			state.orderState = OrderState.UNDEFINE;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(couponSuccessChecked, (state, action) => {
				const totalOrderValueWithShipping = action.payload.totalOrderValue.cost + (state.shippingCosts ? state.shippingCosts.cost : 0);
				state.totalBillingAmount.cost = roundAndScale(Math.max(0, totalOrderValueWithShipping - action.payload.coupon.coupon.eligibleAmount.value), 2);
				state.totalBillingAmount.currency = action.payload.totalOrderValue.currency;

				state.orderItemsPriceSumWithShipping.value = totalOrderValueWithShipping;
				state.orderItemsPriceSumWithShipping.currency = action.payload.totalOrderValue.currency;

				state.isLoading = false;
			})
			.addCase(couponErrorChecked, state => {
				state.isLoading = false;
			})
			.addCase(toCartAdded, state => {
				state.maxPage = 0;
				state.currentPage = 0;
			})
			.addCase(couponReset, state => {
				if (!state.order) state.order = null;
				if (state.orderItemsPriceSumWithShipping) {
					state.totalBillingAmount.cost = state.orderItemsPriceSumWithShipping.cost;
				}
				state.orderItemsWithFullPriceSum = state.orderItemsWithFullPriceSum
					? { cost: state.orderItemsWithFullPriceSum.cost, currency: state.orderItemsWithFullPriceSum.currency }
					: { cost: 0, currency: null };
				state.shippingCosts = state.shippingCosts
					? { cost: state.shippingCosts.cost, currency: state.shippingCosts.currency }
					: { cost: 0, currency: null };
			});

	}
});

export const {
	cartAndCouponSuccessChecked,
	orderStateReset,
	orderStateSet,
	addressSet,
	paymentSet,
	shopDonePageClosed,
	orderDetailsCleared,
	paymentSuccessVerified,
	checkoutPageStepSet,
	paymentInitiated,
	expressPaymentInitiated,
	orderSuccessCreated,
	cartSuccessChecked,
	cartFailureChecked,
	cartRequestChecked,
	paymentFailureVerified,
	paymentSuccess,
	orderFailureCreated,
	maximumCheckoutPageStepSet,
	expressOrderSuccessCreated,
	slyCustomerDataSet,
	expressOrderErrorCreated
} = orderSlice.actions;

export default orderSlice.reducer;
