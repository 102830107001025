// @flow
import React, { useEffect } from "react";
import CartItemContainer from "../../../container/checkout/mainContentBox/CartItemContainer";
import PriceOverviewContainer from "../../../container/checkout/dataSummaryBox/PriceOverviewContainer";
import type Price from "../../../models/Price";
import type { translate } from "../../../i18next";
import { toast } from "react-toastify";
import "./Cart.scss";
import PickupLocationInfoContainer from "../../../container/pickupLocation/PickupLocationInfoContainer";
import CouponContainer from "../../../container/checkout/dataSummaryBox/CouponContainer";
import { SpsCustomer } from "../../../reducers/couponReducer";
import { SlyCustomer} from "../../../reducers/couponReducer";

export type CartProps = {
	shippingText: string,
	shippingCost: Price,
	isCartInvalid: boolean,
	withShipping: boolean,
	withMounting: boolean,
	withPickup: boolean,
	items: any,
	sum: any,
	total: any,
	paymentDetails: any,
	createOrder: () => void,
	nextPage: () => void,
	checkCart: () => void,
	cartVerificationError: boolean,
	hasCashPayment: boolean,
	hasPayPalPayment: boolean,
	hasPayPalExpress: boolean,
	hasPrepaidPayment: boolean,
	hasPRZELEWY24Payment: boolean,
	hasSepaPayment: boolean,
	hasPayLaterPayment: boolean,
	hasCardPayment: boolean,
	showPickupLocation: boolean,
	t: translate,
	hasPayment: boolean,
	dealerPhone: string,
	paymentApiError: boolean,
	spsCustomer: ?SpsCustomer,
	slyCustomer: ?SlyCustomer,
};

const Cart = (props: CartProps) => {
	useEffect(() => {
		if (props.cartVerificationError) {
			toast(props.t("cart.expired"));
		}
		if (props.paymentApiError) {
			toast(props.t("cart.paymentApiError"));
		}
	});

	return <div className={"hpm-cart"}>
		<div className={"hpm-cartContainer"}>
			{props.hasPayment &&
			<div className="hpm-mainContentBox">
				<div className={"hpm-cartTitle"}>{props.t("cart.cartLabel")}</div>
				<ul className="hpm-cart">
					{props.items.map((item, index) => <CartItemContainer key={index} cartItem={item}/>)}
				</ul>
				<div className="hpm-box hpm-shipping">
					<span className="hpm-title">{props.t("cart.expectedDeliveryDate.expectedDeliveryDateLabel")}</span>
					<table>
						<tbody>
							<tr>
								<td>{props.shippingText}</td>
							</tr>
						</tbody>
					</table>
				</div>
				{props.showPickupLocation &&
				<div className="hpm-box">
					<span className="hpm-title">
						{props.withPickup ? props.t("cart.articlePickupLocation") : props.t("cart.articleMountLocation")}
					</span>
					<div>
						<PickupLocationInfoContainer/>
					</div>
				</div>
				}
				<div className="hpm-box hpm-payment">
					<span className="hpm-title">{props.t("cart.paymentMethods.availablePaymentTypes")}</span>
					<div className={props.hasPayment ? "flex-container" : ""}>
						{props.hasPayPalPayment && (
							<span className="hpm-paymentcarts hpm-paypal">
								{props.t("cart.paymentMethods.paypal")}
							</span>
						)}
						{props.hasPayLaterPayment && (
							<span className="hpm-paymentcarts hpm-paypal">
								{props.t("cart.paymentMethods.pay-later")}
							</span>
						)}
						{props.hasCardPayment && (
							<span className="hpm-paymentcarts hpm-card">
								{props.t("cart.paymentMethods.card")}
							</span>
						)}
						{props.hasPRZELEWY24Payment && (
							<span className="hpm-paymentcarts hpm-przelewy24">
								{props.t("cart.paymentMethods.przelewy24")}
							</span>
						)}
						{props.hasPrepaidPayment && (
							<span className="hpm-paymentcarts hpm-bankWire">
								{props.t("cart.paymentMethods.prepaid")}
							</span>
						)}
						{!props.withShipping && props.hasCashPayment && (
							<span className="hpm-paymentcarts hpm-euro">
								{props.t("cart.paymentMethods.cash")}
							</span>
						)}
						{props.hasSepaPayment && (
							<span className="hpm-paymentcarts hpm-sepa">
								{props.t("cart.paymentMethods.sepa")}
							</span>
						)}
					</div>
				</div>
			</div>
			}
			{!props.hasPayment &&
			<div className="hpm-cartErrorContent">
				<div className="hpm-cartErrorSpacer"></div>
				<div className="hpm-cartErrorMessage">
					{props.t("cart.error.infoTextPartOne")}
				</div>
				{(props.dealerPhone && props.dealerPhone !== "0") &&
					<div className="hpm-cartErrorMessage">
						{props.t("cart.error.infoTextPartTwo", {count: props.dealerPhone})}
					</div>
				}
				<div className="hpm-cartErrorMessage">
					{props.t("cart.error.code")}
				</div>
			</div>
			}
			<div className="hpm-dataSummaryBox">
				{!props.slyCustomer && !props.spsCustomer && <CouponContainer t={props.t}/>}
				<PriceOverviewContainer
					submit={() => {
						props.checkCart();
						props.nextPage();
					}}
					specialNextLabel={props.t("cart.calculation.toCheckOut")}
					disabled={props.isCartInvalid}
				/>
			</div>
		</div>
	</div>;
};

export default Cart;
