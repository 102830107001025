import Cart from "../../../components/checkout/mainContentBox/Cart";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {SHIPPING_TYPES} from "../../../models/ShippingTypes";
import {checkCart, NO_PAYPAL_CONFIGURED} from "../../../actions/cart";
import {PAYMENT_METHODS} from "../../../models/PaymentRequests";
import {CouponState} from "../../../reducers/couponReducer";
import {hpmPrice} from "../../../helper/currency";

const mapStateToProps = (state, ownProps) => {
	const items = state.cart.items;
	const {SHIPPING, MOUNT, PICKUP} = SHIPPING_TYPES;

	const withShipping = items.some(item => item.shippingMethod === SHIPPING);
	const withMounting = items.some(item => item.shippingMethod === MOUNT);
	const withPickup = items.some(item => item.shippingMethod === PICKUP);
	const isCartInvalid = (withShipping && (withMounting || withPickup)) || state.order.cartVerificationError;

	let shippingText = withShipping
		? ownProps.t("cart.expectedDeliveryDate.expectedDeliveryDateDefault", {
			min: Math.max(...items.map(item => item.shippingMethod === SHIPPING ? item.product.distributionChannels.shipping.minSupplyDays : 0)),
			max: Math.max(...items.map(item => item.shippingMethod === SHIPPING ? item.product.distributionChannels.shipping.maxSupplyDays : 0))
		})
		: withMounting
			? ownProps.t("cart.shipping.mounting")
			: ownProps.t("cart.expectedDeliveryDate.expectedPickupDateDefault", {
				min: Math.max(...items.map(item => item.shippingMethod === PICKUP ? item.product.distributionChannels.pickup.minSupplyDays : 1)),
				max: Math.max(...items.map(item => item.shippingMethod === PICKUP ? item.product.distributionChannels.pickup.maxSupplyDays : 2))
			});

	const coupon = (state.coupon.coupon && state.coupon.coupon.coupon) ? state.coupon.coupon.coupon : null;
	const couponState = (state.coupon.coupon && state.coupon.coupon.couponState) ? state.coupon.coupon.couponState : null;
	const minOrderValue = (coupon && couponState && CouponState.isBelowRequiredAmount(couponState))
		? hpmPrice(coupon.requiredOrderValue)
		: null;

	return {
		items,
		shippingText,
		withShipping,
		withMounting,
		withPickup,
		isCartInvalid,
		cartVerificationError: state.order.cartVerificationError,
		orderItemsWithFullPriceSum: state.order.orderItemsWithFullPriceSum,
		hasCashPayment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.CASH).length > 0,
		hasPayPalPayment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.PAYPAL).length > 0,
		hasPayPalExpress: state.cart.paypalClientId && state.cart.paypalClientId !== NO_PAYPAL_CONFIGURED,
		hasPrepaidPayment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.PREPAID).length > 0,
		hasPRZELEWY24Payment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.PRZELEWY24).length > 0,
		hasSepaPayment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.SEPA).length > 0,
		hasPayLaterPayment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.PAY_LATER).length > 0,
		hasCardPayment: state.cart.paymentMethods
			.filter(method => method.paymentMethod === PAYMENT_METHODS.CARD).length > 0,
		showPickupLocation: state.location.pickupLocation && (withPickup || withMounting),
		hasPayment: state.cart.paymentMethods.length > 0,
		dealerPhone: state.config.dealerDetails.phone,
		paymentApiError: state.cart.paymentApiError,
		spsCustomer: state.coupon.sps,
		slyCustomer: state.coupon.sly,
		minOrderValue, // Add minOrderValue here
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		checkCart: () => dispatch(checkCart()),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Cart));
