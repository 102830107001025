// @flow
import React from "react";
import type Price from "../../../models/Price";
import {currencyConverter} from "../../../helper/currency";
import {CHECKOUT_PAGE_STATUS} from "../CheckoutPage";
import ButtonSelector from "./ButtonSelector";
import type {translate} from "../../../i18next";
import "./PriceOverview.scss";
import type {PaymentFacility} from "../../../api/PaymentApi";
import type {SlyCustomer, SpsCustomer} from "../../../reducers/couponReducer";
import SpsCouponContainer from "../../../container/checkout/dataSummaryBox/SpsCouponContainer";
import SlyCouponContainer from "../../../container/checkout/dataSummaryBox/SlyCouponContainer";

type PriceOverviewProps = {
	paymentDetails: {},
	page: number,
	disabled: ?boolean,
	withMounting: boolean,
	submit: () => void,
	toggleAccept: () => void,
	accept: ?boolean,
	shippingCost: Price,
	coupon: Price,
	orderItemsPriceSum: Price,
	total: Price,
	specialNextLabel: ?string,
	t: translate,
	hasPayPalExpressClientId: boolean,
	withPayPalExpressCheckout: boolean,
	paymentFacility: PaymentFacility,
	setPayment: string => void,
	initPayment: string => void,
	submitOrder: () => void,
	hasPayment: ?boolean,
	initCapturePayment: (string, string) => void,
	finishCapturePayment: (string) => void,
	renderP24Button: boolean,
	minOrderValue: Price,
	spsCustomer: ?SpsCustomer,
	slyCustomer: ?SlyCustomer,
	eligibleAmount: Price,
}

export const PriceOverview = (props: PriceOverviewProps) => {
	return (
		<div className="hpm-total">
			<span className="hpm-totalTitle">{props.t("cart.calculation.price")}</span>
			<table>
				<tbody>
					<tr>
						<td><span>{props.t("cart.calculation.subtotal")}</span></td>
						<td className="hpm-right">{currencyConverter(props.orderItemsPriceSum)}</td>
					</tr>
					<tr>
						<td><span>{props.t("cart.calculation.shippingCosts")}</span></td>
						<td className="hpm-right">{currencyConverter(props.shippingCost)}</td>
					</tr>
					{props.coupon &&
						<tr>
							<td><span>{props.t("cart.coupon.coupon")}</span></td>
							<td className="hpm-right">{
								currencyConverter({
								value: Math.max(
									-(props.shippingCost.value + props.orderItemsPriceSum.value),
									props.eligibleAmount.value
								),
								currency: props.orderItemsPriceSum.currency
								})
							}
							</td>
						</tr>
					}
					<tr className={"hpm-sum"}>
						<td><span>{props.t("cart.calculation.priceAfterTaxes")}</span></td>
						<td className="hpm-right">{currencyConverter(props.total)}</td>
					</tr>
					{props.minOrderValue &&
						<tr className={"hpm-minOrderValue"}>
							<td><span>{props.t("cart.coupon.minOrderValue")}</span></td>
							<td className="hpm-right">{currencyConverter(props.minOrderValue)}</td>
						</tr>
					}
					{props.toggleAccept &&
					<tr className="hpm-menu">
						<td colSpan={2}>
							<div className="hpm-menuElement hpm-conditions" onClick={() => props.toggleAccept()}>
								<div className={props.accept ? "hpm-circle hpm-selected" : "hpm-circle"}/>
								<div className="hpm-optionText">{props.t("cart.calculation.consent")}</div>
							</div>
						</td>
					</tr>
					}
				</tbody>
			</table>
			{props.spsCustomer && <SpsCouponContainer/>}
			{props.slyCustomer && !props.hideSlyCoupon && <SlyCouponContainer/>}
			<ButtonSelector
				total={props.total}
				paymentFacility={props.paymentFacility}
				initPayment={props.initPayment}
				initCapturePayment={props.initCapturePayment}
				finishCapturePayment={props.finishCapturePayment}
				showPayPalExpressButton={props.page === CHECKOUT_PAGE_STATUS.CART && props.hasPayPalExpressClientId}
				showOrderConfirmButton={props.toggleAccept && !props.accept}
				accept={props.accept && props.toggleAccept}
				activateSpinner={props.page === CHECKOUT_PAGE_STATUS.OVERVIEW}
				disabled={props.disabled}
				minOrderValue={props.minOrderValue}
				btnLabel={props.specialNextLabel || props.t("cart.next")}
				t={props.t}
				submit={props.submit}
				hasPayment={props.hasPayment}
				isCheckoutStepPayment={props.page === CHECKOUT_PAGE_STATUS.PAYMENT}
				renderP24Button={props.renderP24Button && CHECKOUT_PAGE_STATUS.OVERVIEW}
			/>
			<p>
				<small>
					{props.withMounting && props.t("cart.calculation.montageHint")}
				</small>
			</p>
		</div>
	);
};

export default PriceOverview;
