import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import type { ApplicationState } from "../../../reducers";
import { resetCoupon, resetCouponToast, setSlyCoupon } from "../../../actions/coupon";
import SlyCouponCode from "../../../components/checkout/dataSummaryBox/SlyCouponCode";

const mapStateToProps = (state: ApplicationState, ownProps) => {
    return {
        coupon: state.coupon.coupon,
        couponErrorCode: state.coupon.couponErrorCode,
        couponSuccessCode: state.coupon.couponSuccessCode,
        slyCustomer: state.coupon.sly,
        slyCustomerData: state.order.slyCustomerData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetCoupon: () => dispatch(resetCoupon()),
        setSlyCoupon: () => dispatch(setSlyCoupon()),
        resetCouponToast: () => dispatch(resetCouponToast()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SlyCouponCode));
