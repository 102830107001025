// @flow
import type { Coupon, SpsCustomer } from "../reducers/couponReducer";
import {
	couponErrorChecked,
	couponReset,
	CouponState,
	couponSuccessChecked,
	couponToastReset
} from "../reducers/couponReducer";
import CouponApi from "../api/CouponApi";
import type { ApplicationState } from "../reducers";
import { getOrderItemPriceSumWithShipping } from "../container/checkout/dataSummaryBox/PriceOverviewContainer";
import type Price from "../models/Price";
import { hpmPrice } from "../helper/currency";
import type {CustomerResponse} from "../api/CouponApi";
import {slyCustomerDataSet} from "../reducers/orderReducer";

export const SET_COUPON = "SET_COUPON";
export const ISSUE_SPS_COUPON = "ISSUE_SPS_COUPON";
export const ISSUE_SLY_COUPON = "ISSUE_SLY_COUPON";
export const SLY_CUSTOMER = "SLY_CUSTOMER";
export const dispatchCoupon = (coupon: Coupon = {}, totalOrderValue: number) => {
	return dispatch => dispatch(couponSuccessChecked({
		coupon: coupon,
		totalOrderValue: totalOrderValue,
	}));
};

export const couponNotValid = (couponCode: string, error: any, fallbackErrorCode: string = "error") => {
	let errorCode = fallbackErrorCode;
	if (error && error.response && error.response.data && error.response.data.couponState) {
		errorCode = CouponState[error.response.data.couponState];
	}

	return dispatch => dispatch(couponErrorChecked({
		couponCode: couponCode,
		errorCode: errorCode,
	}));
};

export const setCoupon = (code: string, orderItemsPriceSumWithShipping: Price) => {
	return (dispatch, getState: () => ApplicationState) => {
		const couponApi = new CouponApi();
		const dealerNo = getState().config.dealerId;
		const totalOrderValue = hpmPrice(orderItemsPriceSumWithShipping || getOrderItemPriceSumWithShipping(getState()));

		dispatch({type: SET_COUPON});

		return couponApi
			.checkCouponCode(code, dealerNo, totalOrderValue)
			.then(res => {
				dispatch(dispatchCoupon(res, totalOrderValue));
			})
			.catch(e => {
				if (CouponState.isBelowRequiredAmount((e.response && e.response.data && e.response.data.coupon) ? e.response.data.couponState : undefined)) {
					dispatch(dispatchCoupon(e.response.data, totalOrderValue));
				} else {
					dispatch(couponNotValid(code, e, "invalid"));
				}
			});
	};
};

export const setSpsCoupon = () => {
	return (dispatch, getState: () => ApplicationState) => {
		const couponApi = new CouponApi();
		const dealerNo = getState().config.dealerId;
		const spsCustomer: SpsCustomer = getState().coupon.sps || {};
		const totalOrderValue = hpmPrice(getOrderItemPriceSumWithShipping(getState()));
		dispatch({type: ISSUE_SPS_COUPON});

		return couponApi
			.issueSpsCoupon(dealerNo, spsCustomer.spsCustomerId, spsCustomer.spsDealerId)
			.then(res => couponApi
				.checkCouponCode(res.code, dealerNo, totalOrderValue)
				.then(res2 => dispatch(dispatchCoupon(res2, totalOrderValue)))
			)
			.catch(e => {
				dispatch(couponNotValid("SPS Balance", e, "invalid"));
			});
	};
};

export const setSlyCoupon = () => {
	return (dispatch, getState: () => ApplicationState) => {
		const couponApi = new CouponApi();
		const dealerNo = getState().config.dealerId;
		const token = getState().coupon.slyToken;

		const totalOrderValue = hpmPrice(getOrderItemPriceSumWithShipping(getState()));
		dispatch({ type: ISSUE_SLY_COUPON });

		return couponApi
			.issueSlyCoupon(dealerNo, token)
			.then(res => couponApi
				.checkCouponCode(res.code, dealerNo, totalOrderValue, token)
				.then(slyCustomer => dispatch(dispatchCoupon(slyCustomer, totalOrderValue)))
			)
			.catch(e => {
				dispatch(couponNotValid("SLY Coupon", e, "invalid"));
			});
	};
};

export const getSlyCustomer = (dealerNo: string, token: string) => {
	return (dispatch, getState: () => ApplicationState) => {
		const couponApi = new CouponApi();
		return couponApi.getSlyCustomer(dealerNo, token)
			.then((customerResponse: CustomerResponse) => {
				dispatch(slyCustomerDataSet(customerResponse));
			})
			.catch(error => {
				dispatch({
					type: SLY_CUSTOMER ,
					error: error
				});
			});
	};
};

export const resetCoupon = () => {
	return dispatch => dispatch(couponReset());
};

export const resetCouponToast = () => {
	return dispatch => dispatch(couponToastReset());
};
