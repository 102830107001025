/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import * as React from "react";
import {BrandContext, CountryContext} from "./App";
import RedirectService from "../api/RedirectService";
import Spinner from "react-spinkit";
import Map from "./Map";
import Configuration from "../Configuration";
import Loading from "../components/layout/Loading";
import type {DealerType} from "./Dealer";
import {Dealer} from "./Dealer";
import {withTranslation} from "react-i18next";
import ProductApi from "../api/ProductApi";
import {renderBrand} from "./helper/BrandMapping";
import {useState} from "react";
import SearchBox from "./SearchBox";
import {usePosition} from "./usePosition";
import type {LocationType} from "../components/productDetail/NSC/Map";

type Props = {
	t: string => string,
};

export const FILTER_TYPE = {
	WITHOUT_PRICE: "withoutPrice",
	WITH_PRICE: "withPrice",
	WITH_SHOP: "withShop",
};

const GarageDetail = ({t}: Props) => {
	const [garageDetail, setGarageDetail] = React.useState(undefined);
	const [filteredListDealers, setFilteredListDealers] = useState([]);
	const [otherMapDealers, setOtherMapDealers] = useState([]);
	const [boundedMapDealers, setBoundedMapDealers] = useState([]);
	const [filterString, setFilterString] = React.useState("");
	const [showMap, setShowMap] = React.useState(true);
	const [dealerSearchType, setDealerSearchType] = React.useState("");
	const brand = React.useContext(BrandContext);
	const countryCode = React.useContext(CountryContext);
	const {latitude, longitude, error} = usePosition();
	const [selectedLocation, setSelectedLocation] = useState<LocationType>(null);

	const [latMemo, setLatMemo] = React.useState(latitude);
	const [longMemo, setLongMemo] = React.useState(longitude);

	React.useEffect(() => {
		if (latitude && !latMemo) {
			setLatMemo(latitude);
		}
		if (longitude && !longMemo) {
			setLongMemo(longitude);
		}
	}, [latMemo, latitude, longMemo, longitude]);


	const handleSelectedLocation = (location: LocationType) => {
		setFilterString("");
		setSelectedLocation(location);
	};

	const handleFilterStringChange = e => {
		setFilterString(e.target.value);
	};

	const dealerList = () => (
		filteredListDealers.map((dealer: DealerType) => (
			<Dealer key={dealer.identifier.dealerNo} dealer={dealer}/>
			))
	);

	React.useEffect(() => {
		const locationOptions = selectedLocation ? {
			latitude: selectedLocation.lat,
			longitude: selectedLocation.lng,
			searchRadius: 2000,
		} : (latMemo)
			? {
				latitude: latMemo,
				longitude: longMemo,
				searchRadius: 10000
			}
			: null;


		const productApiNew = new ProductApi(undefined, {configIdType: undefined, intent: undefined, locationOptions});
		const country = new URLSearchParams(window.location.search).get("country");
		productApiNew.getDealerForBrand(brand, country || countryCode).then(res => setGarageDetail(res));
	}, [brand, countryCode, selectedLocation, longMemo, latMemo]);

	React.useEffect(() => {
		if (!garageDetail) {
			return;
		}

		const allDealers = garageDetail.dealersForBrand;

		const filteredDealers = allDealers.filter(dealer => {
			const fuzzy = filterString.toLowerCase();
			const { name, address, dealerConfiguration } = dealer;
			const { shopEnabled, productPricesDisabled } = dealerConfiguration;

			if (dealerSearchType === FILTER_TYPE.WITHOUT_PRICE && (shopEnabled || !productPricesDisabled)) {
				return false;
			}
			if (dealerSearchType === FILTER_TYPE.WITH_PRICE && (shopEnabled || productPricesDisabled)) {
				return false;
			}
			if (dealerSearchType === FILTER_TYPE.WITH_SHOP && (!shopEnabled)) {
				return false;
			}
			if (address && fuzzy) {
				const filterObject = [name, address.town, address.street, address.countryCode, address.streetNumber, address.postalCode];
				return filterObject.some(it => it && it.toLowerCase().includes(fuzzy));
			}
			return true;
		});

		const localDealers = filteredDealers.filter(dealer => {
			const distance = parseFloat(dealer.distance.replace(" km"));
			return distance <= 20;
		});

		let filteredMapDealers = localDealers.length < 1 ? filteredDealers.slice(0, 2) : localDealers;

		setFilteredListDealers(filteredDealers);

		if(selectedLocation || latMemo) {
			setBoundedMapDealers(filteredMapDealers);
			setOtherMapDealers(filteredDealers.filter(dealer => !boundedMapDealers.includes(dealer)));
		} else {
			setBoundedMapDealers(filteredDealers);
			setOtherMapDealers([]);
		}
	}, [filterString, dealerSearchType, garageDetail, selectedLocation]);

	if (!garageDetail) {
		return <div className={"GeoLocator info"}>
			<Spinner name="ball-beat" className="hpm-spinnerButton" fadeIn="none"/>
		</div>;
	}

	if (garageDetail.dealersForBrand && garageDetail.dealersForBrand.length > 1) {
		if (garageDetail.dealersForBrand[0].distance && parseFloat(garageDetail.dealersForBrand[0].distance.replace("km", "")) < 2.01) {
			const redirectService = new RedirectService();
			if (!selectedLocation) {
				if (garageDetail.dealersForBrand[0].shopBaseUrl) {
					redirectService.enableUrl(
						garageDetail.dealersForBrand[0].shopBaseUrl,
						garageDetail.dealersForBrand[0].identifier.dealerNo,
						brand
					);
				} else {
					redirectService.enableDealer(garageDetail.dealersForBrand[0].identifier.dealerNo);
				}
			}
		}
	}

	const toggleSearchDealerType = dealerType => {
		dealerType !== dealerSearchType
			? setDealerSearchType(dealerType)
			: setDealerSearchType("");
	};

	return (
		<React.Fragment>
			{!!error ? <div style={{width: "100%", maxWidth: "1200px", margin: "0 auto 0", border:"1px solid #FAFAFA"}}>
				<p style={{margin: "80px 0.5em 80px"}}>{t("nsc.locationNotDetermined", {brand: renderBrand(brand)})}</p>
			</div> : <></>}
			<div className="page-content-block">
				<div className="page-middle" style={{width: "100%", maxWidth: "1200px", margin: "0 auto"}}>
					<div className={"GeoLocator control"}>
						{showMap ? (
							<span className={"searchBox preview-search"}>
								<SearchBox onLocationSelect={handleSelectedLocation} countryCode={countryCode}/>
								<label className="ico search" htmlFor="dealer-class-10"/>
							</span>
						) : (
							<div className={"filter-box-container-close-map"}>
								<div className={"filter-main"}>
									<div className={"filterInput-close"}>
										<input type="search" list={"dealers"}
											   value={filterString}
											   placeholder={t("nsc.filter")}
											   onChange={handleFilterStringChange}
											   className="filter-close-input openInput"
										/>
										<span/>
									</div>
								</div>
							</div>
						)}
						<div className="geolocatorLegend">
							<div
								className={"indicator location " + (dealerSearchType === FILTER_TYPE.WITHOUT_PRICE ? " checked" : "")}
								onClick={() => toggleSearchDealerType(FILTER_TYPE.WITHOUT_PRICE)}><span className="text">{t("nsc.offer.withoutPrice")}</span>
							</div>
							<div className={"indicator euro " + (dealerSearchType === FILTER_TYPE.WITH_PRICE ? " checked" : "")}
								 onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_PRICE)}><span className="text">{t("nsc.offer.withPrice")}</span>
							</div>
							<div className={"indicator shop with-shop-preview" + (dealerSearchType === FILTER_TYPE.WITH_SHOP ? " checked" : "")}
								 onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_SHOP)}><span className="text">{t("nsc.offer.withShop")}</span>
							</div>
						</div>

						<button type={"button"} onClick={() => setShowMap(!showMap)}>{
							showMap ? t("nsc.closeMap") : t("nsc.openMap")
						}</button>
					</div>
					{showMap ? (
						<div className={"GeoLocator"}>
							<Map
								longitude={longitude}
								latitude={latitude}
								error={error}
								otherDealers={otherMapDealers}
								boundedDealers={boundedMapDealers}
								googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Configuration.value("googleMapsApiKey")}&v=3.exp&libraries=places`}
								loadingElement={<Loading/>}
								containerElement={<div className={"GeoLocator Map"}/>}
								mapElement={<div/>}
								onLocationSelect={handleSelectedLocation}
								selectedLocation={selectedLocation}
							/>
							<div className={"GeoLocator List dealer-list"}>
								<span className="filter-span">
									<div className={"filter-box-container-nsc"}>
										<input type="search" list={"dealers"}
											   value={filterString}
											   placeholder={t("nsc.filter")}
											   onChange={handleFilterStringChange}
											   className={"openInput"}
										/>
										<label className="filter-label"/>
									</div>
								</span>
								<div className={"listContainer"}>
									{dealerList()}
								</div>
							</div>
						</div>
					) : (
						<div className={"GeoLocator List"}>
							{dealerList()}
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(GarageDetail);
