// @flow

import CartStorage from "../helper/storage/CartStorage";
import type {Item} from "../models/Item";
import type {PaymentFacility, PaymentMethod} from "../api/PaymentApi";
import {createSlice} from "@reduxjs/toolkit";
import {configSet} from "./configReducer";
import {orderDetailsCleared} from "./orderReducer";

export type CartReducerState = {
	dealerId: ?string,
	items: Item[],
	isLoading: boolean,
	paymentFacility: PaymentFacility,
	paymentMethods: PaymentMethod[],
	paypalClientId: string,
	assignPaymentErrors: {},
	paymentApiError: boolean,
};

const initialState = {
	dealerId: null,
	items: [],
	isLoading: false,
	paymentFacility: {},
	paymentMethods: [],
	paypalClientId: null,
	assignPaymentErrors: {},
	paymentApiError: false,
};

const setCart = (cart, state) => {
	const cartStorage = new CartStorage(state.dealerId);
	cartStorage.set(JSON.stringify(cart));
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		paymentMethodsRequestGotten(state) {
			state.paymentMethods = [];
			state.paymentFacility = {};
			state.isLoading = true;
			state.paymentApiError = false;
		},
		paymentMethodsSuccessGotten(state, action) {
			state.paymentMethods = action.payload.paymentMethods;
			state.paypalClientId = action.payload.clientId;
			state.isLoading = false;
		},
		paymentMethodsFailureGotten(state) {
			state.paymentMethods = [];
			state.isLoading = false;
			state.paymentApiError = true;
		},
		paymentProviderRequestSet(state) {
			state.paymentFacility = {};
			state.isLoading = true;
		},
		paymentProviderSuccessSet(state, action) {
			state.paymentFacility.account = action.payload.account;
			state.paymentFacility.paymentProvider = action.payload.paymentProvider;
			state.paymentFacility.paymentMethod = action.payload.paymentMethod;

			state.isLoading = false;
		},
		paymentProviderFailureSet(state, action) {
			const errors = state.assignPaymentErrors;
			errors[action.payload.paymentMethod] = true;

			state.assignPaymentErrors = {...errors};
			state.isLoading = false;
		},
		createOrderRequested(state) {
			state.assignPaymentErrors = {};
			state.paymentApiError = false;
		},
		createOrderFailed(state) {
			state.isLoading = false;
			state.paymentApiError = true;
		},
		verifyPaymentFailed(state) {
			state.isLoading = false;
			state.paymentApiError = true;
		},
		createExpressOrderError(state) {
			state.isLoading = false;
			state.paymentApiError = true;
		},
		paypalExpressProviderSuccessSet(state, action) {
			state.paypalClientId = action.payload;
		},
		toCartAdded(state, action) {
			let itemFound = false;
			state.items = state.items.map(item => {
				if (action.payload.product.id === item.product.id) {
					item.amount++;
					itemFound = true;
				}
				return item;
			});
			if (itemFound === false) {
				state.items.push({
					product: action.payload.product,
					amount: 1,
					shippingMethod: action.payload.shippingMethod
				});
			}

			setCart({items: [...state.items]}, state);
			state.items = [...state.items];

		},
		fromCartRemoved(state, action) {
			const items = state.items.filter(item => item.product.id !== action.payload.id);
			setCart({items}, state);
			state.items = items;
		},
		allFromCartRemoved(state) {
			setCart({items: []}, state);
			state.items = [];
		},
		quantitySet(state, action) {
			state.items = state.items.map(item => {
				if (action.payload.id === item.product.id) {
					item.amount = action.payload.amount;
					item.amount = (item.amount < 1) ? 1 : item.amount;
				}
				return item;
			});
			setCart({items: [...state.items]}, state);
		}

	},
	extraReducers: builder => {
		builder
			.addCase(configSet, (state, action) => {
				state.dealerId = action.payload.dealerId;
				state.items = action.payload.cart.items;
			})
			.addCase(orderDetailsCleared, state => {
				setCart({items: []}, state);
				state.items = [];
			});
	}
});

export const {
	paymentMethodsFailureGotten,
	toCartAdded,
	fromCartRemoved,
	allFromCartRemoved,
	paymentMethodsRequestGotten,
	paymentMethodsSuccessGotten,
	paymentProviderFailureSet,
	paymentProviderSuccessSet,
	paypalExpressProviderSuccessSet,
	quantitySet,
	paymentProviderRequestSet,
	verifyPaymentFailed,
	createOrderRequested,
	createOrderFailed,
	createExpressOrderError
} = cartSlice.actions;

export default cartSlice.reducer;
