import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {Item} from "../../../models/Item";
import {getPrice} from "../../../models/Item";
import PriceOverview from "../../../components/checkout/dataSummaryBox/PriceOverview";
import {SHIPPING_TYPES} from "../../../models/ShippingTypes";
import type {ApplicationState} from "../../../reducers";
import {
	acceptOrder,
	finishCapturePayment,
	initiatePayment,
	setAuthorizePaymentProvider,
	setCapturePaymentProvider
} from "../../../actions/order";
import {CHECKOUT_PAGE_STATUS} from "../../../components/checkout/CheckoutPage";
import {PAYMENT_METHODS} from "../../../models/PaymentRequests";
import {CouponState} from "../../../reducers/couponReducer";
import type Price from "../../../models/Price";
import {hpmPrice} from "../../../helper/currency";

export const getShipping = (items: Item[]) => {
	return Math.max(...items.map(
		item => item.shippingMethod === SHIPPING_TYPES.SHIPPING
			? item.product.distributionChannels.shipping.shippingCost.value
			: 0
	));
};
export const getOrderItemsPriceSum = (state: ApplicationState): Price => {
	let sum = 0;
	state.cart.items.forEach(item => {
		sum += item.amount * (getPrice(item).value);
	});
	const items = state.cart.items;
	const currency = getPrice(items[0]).currency;

	return {
		value: sum,
		currency: currency
	};
};
export const getOrderItemPriceSumWithShipping = (state: ApplicationState): Price => {
	const orderItemsPriceSum = getOrderItemsPriceSum(state);
	const items = state.cart.items;
	const currency = getPrice(items[0]).currency;
	const shippingCost = {value: getShipping(items), currency};

	return {
		value: orderItemsPriceSum.value + shippingCost.value,
		currency: currency
	};
};
export const getTotalBillingAmount = (state: ApplicationState): Price => {
	const orderItemsPriceSumWithShipping = state.order.orderItemsPriceSumWithShipping || getOrderItemPriceSumWithShipping(state);
	const items = state.cart.items;
	const currency = getPrice(items[0]).currency;
	const coupon = (state.coupon.coupon && state.coupon.coupon.coupon) ? state.coupon.coupon.coupon : null;

	return {
		value: Math.max(0, orderItemsPriceSumWithShipping.value - (coupon ? coupon.eligibleAmount.value : 0)),
		currency: currency
	};
};

const mapStateToProps = (state: ApplicationState, ownProps) => {
	const items = state.cart.items;
	const currency = getPrice(items[0]).currency;
	const shippingCost = {value: getShipping(items), currency};
	const coupon = (state.coupon.coupon && state.coupon.coupon.coupon) ? state.coupon.coupon.coupon : null;
	const eligibleAmount = (state.coupon.coupon &&  state.coupon.coupon.coupon.eligibleAmount);
	const couponState = (state.coupon.coupon && state.coupon.coupon.couponState) ? state.coupon.coupon.couponState : null;
	const totalBillingAmount = hpmPrice(state.order.totalBillingAmount || getTotalBillingAmount(state));

	return {
		withMounting: items.some(item => item.shippingMethod === SHIPPING_TYPES.MOUNT),
		page: state.order.currentPage,
		paymentDetails: state.order.paymentDetails,
		coupon: (coupon && CouponState.isValid(couponState))
			? {value: "-" + coupon.displayValue, currency: coupon.displayUnit}
			: null,
		minOrderValue: (coupon && couponState && CouponState.isBelowRequiredAmount(couponState))
			? hpmPrice(coupon.requiredOrderValue)
			: null,
		orderItemsPriceSum: hpmPrice(state.order.orderItemsPriceSum || getOrderItemsPriceSum(state)),
		total: totalBillingAmount,
		shippingCost,
		eligibleAmount,
		spsCustomer: state.coupon.sps,
		slyCustomer: state.coupon.sly,
		hasPayPalExpressClientId: state.cart.paypalClientId &&
			totalBillingAmount.value > 0 &&
			state.cart.paymentMethods.filter(method => method.paymentMethod === PAYMENT_METHODS.PAYPAL_EXPRESS).length > 0,
		hasPayment: state.order.currentPage !== CHECKOUT_PAGE_STATUS.CART ||
			(state.order.currentPage === CHECKOUT_PAGE_STATUS.CART && state.cart.paymentMethods.length > 0)

	};
};

const mapDispatchToProps = dispatch => {
	return {
		initPayment: (paymentProvider: string, paymentReference: string) => {
			dispatch(setAuthorizePaymentProvider(paymentProvider, paymentReference));
			dispatch(initiatePayment());
		},
		submitOrder: () => dispatch(acceptOrder()),
		initCapturePayment: (paymentProvider: string, paymentReference: string) => {
			dispatch(setCapturePaymentProvider(paymentProvider, paymentReference));
		},
		finishCapturePayment: (paymentReference: string) => dispatch(finishCapturePayment(paymentReference)),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PriceOverview));
