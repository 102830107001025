// @flow
import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {email, required, length} from "redux-form-validators";
import {connect} from "react-redux";
import PriceOverviewContainer from "../../../container/checkout/dataSummaryBox/PriceOverviewContainer";
import type {Adress} from "../../../models/Adress";
import {translate} from "react-i18next";
import type {Country} from "../../../models/Country";
import {v4 as uuidv4} from "uuid";
import "./Adress.scss";

export type AdressFormProps = {
	reset: () => void,
	createOrder: () => void,
	nextPage: () => void,
	saveAdress: () => void,
	sum: ?number,
	adress: Adress,
	shippingAdress: Adress,
	shippableCountries: Country[],
	handleSubmit: () => void,
	withShipping: boolean,
	t: translate
}

export class AdressForm extends React.Component<AdressFormProps, null> {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(formValues) {
		this.props.saveAdress(formValues);
		this.props.nextPage();
	};

	renderInput({input, label, meta, required = true, autocomplete}) {
		return (
			<React.Fragment>
				<label htmlFor={input.name}
					   className={meta.touched && meta.error ? "hpm-error" : ""}>{label} {required ? "*" : ""}</label>
				<input {...input} autoComplete={autocomplete}/>
			</React.Fragment>
		);
	}

	renderTextarea({input, label, meta, required = true}) {
		return (
			<React.Fragment>
				<label htmlFor={input.name}
					   className={meta.touched && meta.error ? "hpm-error" : ""}>{label} {required ? "*" : ""}</label>
				<textarea {...input}/>
			</React.Fragment>
		);
	}

	renderSelect({input, label, meta, children, value}) {
		return (
			<React.Fragment>
				<label htmlFor={input.name} className={meta.touched && meta.error ? "hpm-error" : ""}>{label}*</label>
				<select value={value} {...input}>
					{children}
				</select>
			</React.Fragment>
		);
	}

	renderCheckbox({input, label}) {
		return (
			<div className="hpm-menuElement">
				<label htmlFor={label}>
					<div className={input.value ? "hpm-circle hpm-selected" : "hpm-circle"}/>
					<div className="hpm-optionText">{label}</div>
				</label>
				<input {...input} id={label} type="checkbox"/>
			</div>
		);
	};

	render() {
		return (
			<div className={"hpm-address"}>
				<form className={"hpm-adressForm"}>
					<div className={"hpm-adressContainer"}>
						<div className="hpm-mainContentBox">
							<div
								className={"hpm-adressTitle"}>{this.props.t("cart.personalInformation.personalInformationLabel")}</div>
							<fieldset className={"formWrapper"}>
								<div className="hpm-firstNameField">
									<Field label={this.props.t("cart.personalInformation.firstName")}
										   component={this.renderInput} name="name" autocomplete="given-name" validate={[required(), length({min: 2})]}/>
								</div>
								<div className="hpm-lastNameField">
									<Field label={this.props.t("cart.personalInformation.lastName")}
										   component={this.renderInput} name="familyname" autocomplete="family-name" validate={[required(), length({min: 2})]}/>
								</div>
								<Field label={this.props.t("cart.personalInformation.street")}
									   component={this.renderInput} name="street" validate={required()}/>
								<div className="hpm-zipcodeField">
									<Field label={this.props.t("cart.personalInformation.zipCode")}
										   component={this.renderInput} name="postalCode" validate={required()}/>
								</div>
								<div className="hpm-cityField">
									<Field label={this.props.t("cart.personalInformation.city")}
										   component={this.renderInput} name="city" validate={required()}/>
								</div>
								<div className="hpm-emailField">
									<Field label={this.props.t("cart.personalInformation.emailAdress")}
										   component={this.renderInput} name="email" validate={[required(), email()]}/>
								</div>
								<div className="hpm-phoneField">
									<Field label={this.props.t("cart.personalInformation.phone")}
										   component={this.renderInput} name="phone" type={"phone"} validate={required()}/>
								</div>
								<div className={"hpm-countryCodeField"}>
									<Field value={this.props.shippableCountries[0].countryCode}
										   label={this.props.t("cart.personalInformation.country.countryLabel")}
										   class={"hpm-countryCodeField"}
										   component={this.renderSelect} name="countryCode" validate={required()}>
										{this.props.shippableCountries.map(country =>
											<option value={country.countryCode} key={uuidv4()} disabled={country.countryCode === ""}>
												{country.countryName}
											</option>
										)}
									</Field>
								</div>
								<Field label={this.props.t("cart.personalInformation.vin")} validate={false} required={false}
									   component={this.renderInput} name="vin" type={"text"}/>
								<Field label={this.props.t("cart.personalInformation.comment")} validate={false} required={false}
									   component={this.renderTextarea} name="comment" type={"text"}/>
							</fieldset>

							{this.props.withShipping &&
								<React.Fragment>
									<div className="hpm-menu">
										<Field name="shippingAdress" id="shippingAdress" component={this.renderCheckbox}
											   label={this.props.t("cart.personalInformation.isBillingEqualToShipping")}/>
									</div>
									{this.props.shippingAdress &&
									<div className="hpm-shippingAddressContainer">
										<div className={"hpm-adressTitle"}>
											{this.props.t("cart.personalInformation.shippingAdressData")}
										</div>
										<fieldset>
											<Field label={this.props.t("cart.personalInformation.firstName")}
												   component={this.renderInput} name="shippingName" validate={required()}/>
											<Field label={this.props.t("cart.personalInformation.lastName")}
												   component={this.renderInput} name="shippingFamilyname"
												   validate={required()}/>
											<Field label={this.props.t("cart.personalInformation.street")}
												   component={this.renderInput} name="shippingStreet" validate={required()}/>
											<div className="hpm-zipcodeField">
												<Field label={this.props.t("cart.personalInformation.zipCode")}
													   component={this.renderInput} name="shippingPostalCode"
													   validate={required()}/>
											</div>
											<div className="hpm-cityField">
												<Field label={this.props.t("cart.personalInformation.city")}
													   component={this.renderInput} name="shippingCity"
													   validate={required()}/>
											</div>
											<Field value={this.props.shippableCountries[0].countryCode}
												   label={this.props.t("cart.personalInformation.country.countryLabel")}
												   component={this.renderSelect} name="shippingCountryCode">
												{this.props.shippableCountries.map(country =>
													<option value={country.countryCode} key={uuidv4()}
														disabled={country.countryCode === ""}>
														{country.countryName}
													</option>
												)}
											</Field>
										</fieldset>
									</div>
									}
								</React.Fragment>
							}

							<div className="hpm-requiredFootnote">
								(*) {this.props.t("cart.personalInformation.requiredField")}
							</div>
						</div>
						<div className="hpm-dataSummaryBox">
							<PriceOverviewContainer submit={this.props.handleSubmit(this.handleSubmit)}/>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const reduxAdressForm = reduxForm({
	form: "addressForm",
	enableReinitialize: true
})(AdressForm);

const selector = formValueSelector("addressForm");

export default connect(state => {
	const shippingAdress = selector(state, "shippingAdress");
	return {shippingAdress};
})(reduxAdressForm);
