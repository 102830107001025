// @flow

import React, {useState} from "react";
import "react-medium-image-zoom/dist/styles.css";
import "./Gallery.scss";
import "./swiper.scss";
import {Navigation, Pagination} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


export type SlImage = {
	url: string,
	name: string,
	attachmentUrl: string,
	previewImage: ?SlImage,
};

type GalleryProps = {
	images: SlImage[],
	videos: any[],
}

	const Gallery = ({ images, videos }: GalleryProps) => {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(0);

	const openModal = index => {
		setSelectedImage(index);
		setIsModalOpen(true);
	};

		const closeModal = () => {
			setIsModalOpen(false);
			setSelectedImage(0);
		};

		const renderMedia = () => {
			const hasMultipleGalleryImages = images.length > 1;

			const imageList = images.map(it => ({
				url: it.attachmentUrl,
				name: it.name,
				attachmentUrl: it.attachmentUrl,
				previewImage: it.previewImage,
			}));

			return (
				<React.Fragment>
					<div className={"swiper"}>
						<Swiper
							modules={[Navigation, Pagination]}
							className={"swiper-container swiper-gal"}
							slidesPerView={1}
							spaceBetween={0}
							slidesPerGroup={1}
							autoHeight={false}
							loop={true}
							grabCursor={true}
							pagination={{
								el: ".swiper-pagination",
								clickable: true,
							}}
							navigation={{
								nextEl: ".swiper-button-next-gal",
								prevEl: ".swiper-button-prev-gal",
							}}
						>
							{imageList.map((it, index) => (
								<SwiperSlide className="swiper-slide" key={"image_" + index}>
									<div className="img" style={{ backgroundImage: `url('${it.attachmentUrl || it.previewImageUrl}')` }}
										 onClick={() => openModal(index)}
									>
									</div>
								</SwiperSlide>
							))}
							{videos.map((video, index) => (
								<SwiperSlide key={"video_" + index}>
									<video controls className={"video-slide"}>
										<source src={video.attachmentUrl} type="video/mp4"/>
										Your browser does not support the video tag.
									</video>
								</SwiperSlide>
							))}
						</Swiper>
						<div className={`swiper-button-next swiper-button-next-gal ${!hasMultipleGalleryImages ? "gal-hidden" : ""}`}/>
						<div className={`swiper-button-prev swiper-button-prev-gal ${!hasMultipleGalleryImages ? "gal-hidden" : ""}`}/>
					</div>
					<div className={`swiper-pagination swiper-pagination-gal previewImages image ${!hasMultipleGalleryImages ? "gal-hidden" : ""}`}/>
					{imageList.map((it, index) => (
						<style key={"teaser_style_" + index}>
							{`.swiper-pagination-gal .swiper-pagination-bullet:nth-child(${index + 1}) {`}
							{`background-image: url('${it.previewImage ? it.previewImage.attachmentUrl : it.attachmentUrl}') !important;`}
							{"}"}
						</style>
					))}
					{videos.map((video, index) => (
						<style key={"teaser_style_video_" + index}>
							{`.swiper-pagination-gal .swiper-pagination-bullet:nth-child(${imageList.length + index + 1}) {`}
							{"opacity: 0.5 !important;"}
							{`background-image: url('${video.previewImage ? video.previewImage.attachmentUrl : video.attachmentUrl}') !important;`}
							{"}"}
							{`.swiper-pagination-gal .swiper-pagination-bullet:nth-child(${imageList.length + index + 1}):after {`}
							{"content: \"\";"}
							{"}"}
						</style>
					))}
				</React.Fragment>
			);
		};

		return (
			<div className="hpm-gallery" data-testid={"gallery"}>
				<div className="image">
					{renderMedia()}
				</div>
				{isModalOpen && (
					<div className="hpm-gallery-modal" onClick={closeModal}>
						<div className="hpm-gallery-modal-content" onClick={e => e.stopPropagation()}>
							<span className="hpm-gallery-modal-close" onClick={closeModal}></span>
							<Swiper
								modules={[Navigation]}
								className={"swiper-container swiper-modal"}
								slidesPerView={1}
								spaceBetween={0}
								slidesPerGroup={1}
								loop={true}
								grabCursor={true}
								navigation={{
									nextEl: ".swiper-button-next-modal",
									prevEl: ".swiper-button-prev-modal",
								}}
								initialSlide={selectedImage}
							>
								{images.map((image, index) => (
									<SwiperSlide className="swiper-slide" key={"modal_image_" + index}>
										<img src={image.attachmentUrl} alt={image.name} className="hpm-modal-image"/>
									</SwiperSlide>
								))}
								{videos.map((video, index) => (
									<SwiperSlide className="swiper-slide" key={"modal_video_" + index}>
										<video controls className="hpm-modal-video">
											<source src={video.attachmentUrl} type="video/mp4"/>
											Your browser does not support the video tag.
										</video>
									</SwiperSlide>
								))}
							</Swiper>
							<div className="swiper-button-next swiper-button-next-modal"/>
							<div className="swiper-button-prev swiper-button-prev-modal"/>
						</div>
					</div>
				)}
			</div>
		);
};

export default Gallery;
