// @flow
import React from "react";
import classNames from "classnames";
import Button from "../layout/Button";
import {currencyConverter} from "../../helper/currency";
import type {Product} from "../../models/Product";
import {getGalleryPrice} from "../../models/Product";
import {DISTRIBUTION} from "../../models/DistributionChannels";
import "./PriceBox.scss";
import type {DealerDetails} from "../../models/DealerDetails";
import PickupLocationContainer from "../../container/pickupLocation/PickupLocationContainer";
import StringUtils from "../../helper/stringUtils";

type PriceBoxProps = {
	t: any;
	infobox: any,
	addToCart?: ({}, string) => void,
	isShopEnabled: boolean,
	isLeadModuleEnabled: boolean,
	watchlistEnabled: boolean,
	productPricesDisabled?: boolean,
	isBookmarked: boolean,
	product: Product,
	dealerDetails: DealerDetails,
	addBookmark: Product => void,
	removeBookmark: Product => void,
	needsPickupLocation: boolean,
}

type PriceBoxState = {
	selectedOption: ?number
}

export default class PriceBox extends React.Component<PriceBoxProps, PriceBoxState> {
	constructor(props: PriceBoxProps) {
		super(props);
		const {distributionChannels} = this.props.product;
		let selectedOption = DISTRIBUTION.NONE;
		if (distributionChannels.shipping && this.props.isShopEnabled) {
			selectedOption = DISTRIBUTION.SHIPPING;
		}
		if (distributionChannels.mounting) {
			selectedOption = DISTRIBUTION.MOUNTING;
		}
		if (distributionChannels.pickup) {
			selectedOption = DISTRIBUTION.PICKUP;
		}
		this.state = {
			selectedOption,
		};
	}

	selectOption(selectedOption: number) {
		this.setState({selectedOption});
	}

	render() {
		const shippingCost = this.props.product.distributionChannels.shipping
			? currencyConverter(this.props.product.distributionChannels.shipping.shippingCost)
			: "";

		const getRecommendedRetailPrice = () => {
			switch (this.state.selectedOption) {
				case DISTRIBUTION.SHIPPING:
					return shipping.recommendedRetailPrice;
				case DISTRIBUTION.PICKUP:
					return pickup.recommendedRetailPrice;
				case DISTRIBUTION.MOUNTING:
					return mounting.recommendedRetailPrice;
				default:
					return this.props.product.recommendedRetailPrice;
			}
		};

		const label = {
			mounting: this.props.t("productDetail.assemblyPrice"),
			shipping: this.props.t("productDetail.shippingPrice"),
			pickup: this.props.t("productDetail.pickupPrice"),
			discount: this.props.t("productDetail.discountLabel"),
			priceWithVat: this.props.t("productDetail.inclVAT"),
			priceWithoutVat: this.props.t("productDetail.exclVAT"),
			priceWithShipping: this.props.t("productDetail.inclShipping"),
			priceWithoutShipping: this.props.t("productDetail.exclShipping", {shippingCost}),
			priceWithMounting: this.props.t("productDetail.inclMount"),
			priceWithoutMounting: this.props.t("productDetail.exclMount"),
			availableAtTrader: this.props.t("productDetail.availableAtTrader"),
			notAvailable: this.props.t("productDetail.notAvailable"),
			and: this.props.t("productDetail.and")
		};

		const {mounting, shipping, pickup} = this.props.product.distributionChannels;
		const {isAvailable, unitPrice} = this.props.product;
		const recommendedRetailPrice = getRecommendedRetailPrice();
		const price = getGalleryPrice(this.props.product, this.state.selectedOption, this.props.needsPickupLocation);
		const showAddToCartButton = ((mounting || shipping || pickup) && isAvailable && this.props.isShopEnabled === true);
		const showDisclaimer = !!price;
		const showContactButton = !this.props.watchlistEnabled && !showAddToCartButton;

		let priceClass = classNames("hpm-price", {
			"hpm-stroke hpm-pushLeft": recommendedRetailPrice
		});
		let mountingClass = classNames("hpm-circle", {
			"hpm-selected": (this.state.selectedOption === DISTRIBUTION.MOUNTING)
		});
		let shippingClass = classNames("hpm-circle", {
			"hpm-selected": (this.state.selectedOption === DISTRIBUTION.SHIPPING)
		});
		let pickupClass = classNames("hpm-circle", {
			"hpm-selected": (this.state.selectedOption === DISTRIBUTION.PICKUP)
		});

		const getDiscountRate = () => {
			switch (this.state.selectedOption) {
				case DISTRIBUTION.MOUNTING:
					return mounting.discountRate;
				case DISTRIBUTION.PICKUP:
					return pickup.discountRate;
				case DISTRIBUTION.SHIPPING:
					return shipping.discountRate;
				default:
					return 0;
			}
		};

		const showShortText = (props: { minSupplyDays: number, maxSupplyDays: number }) => {
			return props.minSupplyDays <= 1 && props.maxSupplyDays <= 1;
		};

		const isShipping = this.state.selectedOption === DISTRIBUTION.SHIPPING;
		const isPickup = this.state.selectedOption === DISTRIBUTION.PICKUP;
		const isMounting = this.state.selectedOption === DISTRIBUTION.MOUNTING;
		const isShippingTimeVisible = isShipping && this.props.isShopEnabled;
		const isPickUpTimeVisible = isPickup && this.props.isShopEnabled;
		const isMountingTimeVisible = isMounting && this.props.isShopEnabled;
		const isDiscountRateVisible = recommendedRetailPrice && this.state.selectedOption !== DISTRIBUTION.NONE && getDiscountRate() > 0;
		const priceNeedsASlectedLocation = this.props.needsPickupLocation && isMounting;

		const renderPriceBox = () => {
			return (
				<div className="hpm-priceBoxLabel">
					{recommendedRetailPrice &&
					<div className={priceClass}>{currencyConverter(recommendedRetailPrice)}</div>
					}
					<div className="hpm-retailPrice">
						{(priceNeedsASlectedLocation)
							? this.props.t("productDetail.selectLocation")
							: price ? (<>{currencyConverter(price)}
								{unitPrice && (
								<div className="unit-price">
									<small>
										({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²" : unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
									</small>
								</div>
									)}
							</>
							) : (
								this.props.t("productDetail.priceOnRequest")
							)}
					</div>
					{!priceNeedsASlectedLocation &&
						<div className="hpm-info">
							{price && label.priceWithVat}
							{isShipping && <React.Fragment>, {label.priceWithoutShipping}</React.Fragment>}
							{isMounting && <React.Fragment> {label.and} {label.priceWithMounting}</React.Fragment>}
						</div>
					}
					{isShippingTimeVisible &&
					<div className="hpm-info">
						{showShortText(shipping)
							? this.props.t("productDetail.shippingDaysShort")
							: this.props.t("productDetail.shippingDays", {
								min: shipping.minSupplyDays,
								max: shipping.maxSupplyDays
							})
						}
					</div>}
					{isPickUpTimeVisible &&
					<div className="hpm-info">
						{showShortText(pickup)
							? this.props.t("productDetail.pickupDaysShort")
							: this.props.t("productDetail.pickupDays", {
								min: pickup.minSupplyDays,
								max: pickup.maxSupplyDays
							})
						}
					</div>}
					{isMountingTimeVisible &&
					<div className="hpm-info">
						{showShortText(mounting)
							? this.props.t("productDetail.mountingDaysShort")
							: this.props.t("productDetail.mountingDays", {
								min: mounting.minSupplyDays,
								max: mounting.maxSupplyDays
							})
						}
					</div>}
					{isDiscountRateVisible &&
					<div className="hpm-discount">
						{`${label.discount} ${getDiscountRate()}%`}
					</div>}
				</div>
			);
		};

        const renderContactButton = () => {
            return <Button name="cartButton primary" id="hpmt-contactButton">
	<a href={this.props.dealerDetails.phone
                    ? `tel:${this.props.dealerDetails.phone.replace(/ /g, "")}`
                    // eslint-disable-next-line no-script-url
                    : "javascript:void(0)"}
                >
		{this.props.dealerDetails.phone
                        ? `${this.props.t("productDetail.callMe")} (${StringUtils.makeSpacesNonBreaking(this.props.dealerDetails.phone)})`
                        : ""
                    }
	</a>
            </Button>;
        };

		const renderWatchlistButton = () => {
			const isShopEnabled = this.props.isShopEnabled;
			const isSelected = this.props.isBookmarked;

			return (
				<a
					id="hpmt-bookmarkButton"
					className={`hpm-cartButton bookmarks ${
						isShopEnabled && !mounting && !shipping && !pickup ? "isShopDisable" : isShopEnabled ? "isShopEnable" : "isShopDisable"
					} ${isSelected ? "selected" : "not-selected"}`}
					onClick={() => (isSelected ? this.props.removeBookmark(this.props.product) : this.props.addBookmark(this.props.product))}
				>
					<span className="ico"/>
					<span>{isSelected ? this.props.t("productDetail.removeFromBookmarks") : this.props.t("productDetail.addToBookmarks")}</span>
				</a>
			);
		};
		const XOR = (a, b) => {
			return (a && !b) || (!a && b);
		};

		const showShippingTypeSelector = !XOR(XOR(mounting, shipping), pickup) || this.props.isShopEnabled === true;

		return (
			<div className="hpm-priceBox" data-testid="priceBox">
				<div className="hpm-menu">
					{(pickup) &&
					<div className="hpm-menuElement" onClick={() => this.selectOption(DISTRIBUTION.PICKUP)}>
						{showShippingTypeSelector && <React.Fragment>
							<div className={pickupClass}/>
							<div className="hpm-optionText">{label.pickup}</div>
						</React.Fragment>}
					</div>}
					{(mounting) &&
					<div className="hpm-menuElement" onClick={() => this.selectOption(DISTRIBUTION.MOUNTING)}>
						{showShippingTypeSelector && <React.Fragment>
							<div className={mountingClass}/>
							<div className="hpm-optionText">{label.mounting}</div>
						</React.Fragment>}
					</div>}
					{(shipping && this.props.isShopEnabled) &&
					<div className="hpm-menuElement" onClick={() => this.selectOption(DISTRIBUTION.SHIPPING)}>
						{showShippingTypeSelector && <React.Fragment>
							<div className={shippingClass}/>
							<div className="hpm-optionText">{label.shipping}</div>
						</React.Fragment>}
					</div>}

					{(this.props.isShopEnabled && !shipping && !mounting && !pickup)
						? isAvailable
							? label.availableAtTrader
							: label.notAvailable
						: null
					}
				</div>
				{renderPriceBox()}
				<div className="hpm-clear"/>
				<div className="hpm-actions">
					{(showAddToCartButton) && ((this.props.needsPickupLocation && !isShipping)
						? <div className="hpm-cartButton inverted">
							<PickupLocationContainer linkStyle={{display: "block", width: "100%"}}
													 linkLabel={isMounting ? this.props.t("location.locationForMount") : this.props.t("location.locationForPickup")}
							/>
						</div>
						: <Button
							onClick={() => this.props.addToCart(this.state.selectedOption)}
							name="cartButton primary"
							id="hpmt-cartButton">
							{this.props.t("productDetail.addToCart")}
						</Button>
					)}
					{(!showAddToCartButton && this.props.watchlistEnabled && this.props.needsPickupLocation) &&
						<div className="hpm-cartButton inverted">
							<PickupLocationContainer linkStyle={{display: "block", width: "100%"}}
								linkLabel={this.props.t("location.headline")}/>
						</div>}
					{(this.props.watchlistEnabled && !this.props.needsPickupLocation) && renderWatchlistButton()}
					{(showContactButton) && renderContactButton()}
				</div>
				{(showDisclaimer) &&
				<div className="hpm-infoBoxPrice" dangerouslySetInnerHTML={{__html: this.props.infobox}}/>
				}
			</div>
		);
	}
};
