// @flow

import React from "react";
import CarElement from "./CarElement";
import type FilterVehicleSeries from "../../models/FilterVehicleSeries";
import type {translate} from "../../i18next";
import type Brand from "../../models/Brand";
import "./CarSelectionBar.scss";
import {CSSTransition} from "react-transition-group";
import CarModelDropdown from "./CarModelDropdown";
import SwimLane from "../SwimLane";

export type CarSelectionBarProps = {
	t: translate,
	brand: Brand,
	filteredVehicleSeries: FilterVehicleSeries[],
	selectedVehicle: FilterVehicleSeries,
	resetVehicle: () => void,
	setVehicle: (string, string) => void,
};

export type CarSelectionBarState = {
	visibleVehicleModel: string,
	allowsClick: boolean,
	isCarModelSelected: boolean,
}

class CarSelectionBar extends React.Component<CarSelectionBarProps, CarSelectionBarState> {
	constructor() {
		super();
		this.state = {
			visibleVehicleModel: "",
			allowsClick: true,
			isCarModelSelected: false,
		};
	};

	componentDidUpdate(prevProps) {
		if (this.props.filteredVehicleSeries === undefined) {
			this.setState({visibleVehicleModel: ""});
		}
		if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
			this.setState({isCarModelSelected: !!this.props.selectedVehicle});
		}
	}

	toggleCarSelector = model => {
		this.setState({visibleVehicleModel: model});
	};

	setAllowsClick = (allowsClick: boolean) => {
		this.setState({allowsClick});
	};

	handleVehicleSelection = (series, model) => {
		this.props.setVehicle(series, model);
		this.setState({visibleVehicleModel: "", isCarModelSelected: true});
	};

	render() {
		if (!this.props.selectedVehicle && !this.props.filteredVehicleSeries.filter(vehicle => vehicle.categories.length > 0).length) {
			return null;
		}

		const selectedVehicle = this.props.selectedVehicle;
		const brandName = this.props.brand.name;

		const displayTitle = selectedVehicle
			? (selectedVehicle.title && selectedVehicle.title.includes(brandName))
				? selectedVehicle.title
				: `${brandName} ${selectedVehicle.title}`
			: "";

		return (
			<div className={"hpm-carSelectionBar"} data-testid="hpm-carSelectionBar">
				<CSSTransition in={!!this.props.selectedVehicle} unmountOnExit={true} timeout={0}>
					<div
						className={"hpm-infoCar"} onClick={this.props.resetVehicle}>
						<span className={"hpm-selectionBarCarTitle"}> {selectedVehicle ? displayTitle : ""}</span>
						<div className="hpm-close"/>
					</div>
				</CSSTransition>
				{!this.state.isCarModelSelected && (
					<CSSTransition in={!this.props.selectedVehicle} unmountOnExit={false} timeout={0}>
						<div className={"hpm-CarSelectorWrapper"}>
							<SwimLane
							key={this.props.filteredVehicleSeries.length}
							setAllowsClick={this.setAllowsClick}
							childrenCount={this.props.filteredVehicleSeries.length}
							id={"hpm-carSeriesSwimlane"}>
								{this.props.filteredVehicleSeries.map((vehicle: FilterVehicleSeries) => {
								return <div
									key={vehicle.key}>
									<CarElement
										setVehicle={this.props.setVehicle} key={vehicle.key} id={vehicle.key}
										imgSrc={vehicle.imageUrl} name={vehicle.name}
										title={vehicle.title}
										selected={this.props.selectedVehicle && (vehicle.name === this.props.selectedVehicle.name)}
										vehicleModels={vehicle.categories}
										visibleVehicleModel={this.state.visibleVehicleModel}
										toggleVehicleSelector={this.toggleCarSelector}
										carRef={vehicle.carRef}
										allowsClick={this.state.allowsClick}
									/>
									<CarModelDropdown
										key={vehicle.key + "_models"}
										vehicleSeries={vehicle.name}
										setVehicle={this.handleVehicleSelection}
										vehicleModels={vehicle.categories}
										isVisible={this.state.visibleVehicleModel === vehicle.name}
										toggleVehicleSelector={this.toggleCarSelector}
										carRef={vehicle.carRef}
										allowsClick={this.state.allowsClick}
									/>
								</div>;
							})}
							</SwimLane>
						</div>
					</CSSTransition>
					)}
			</div>
		);
	}
}

export default CarSelectionBar;
