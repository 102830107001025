// @flow
import React, {Component} from "react";
import {Route, Router, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {checkConfig} from "./actions/config";
import {getBrandInstances} from "./actions/brand";
import {withTranslation} from "react-i18next";

import "./App.scss";

import ProductsContainer from "./container/gridPages/ProductsContainer";
import ProductContainer from "./container/productDetail/ProductContainer";
import loadProductContainer from "./container/productDetail/loadProductContainer";
import HeaderContainer from "./container/layout/NavigationContainer";
import FooterContainer from "./container/layout/FooterContainer";
import SearchContainer from "./container/gridPages/SearchContainer";
import StartPageContainer from "./container/gridPages/StartPageContainer";
import BrandInstanceContainer from "./container/gridPages/BrandInstanceContainer";
import Loading from "./components/layout/Loading";
import history from "./history";
import BookmarksPageContainer from "./container/wishlist/WishlistPageContainer";
import CheckoutPageContainer from "./container/checkout/CheckoutPageContainer";
import type {ApplicationState} from "./reducers";
import type {ConfigReducerState} from "./reducers/configReducer";
import type {translate} from "./i18next";
import {resetBookmarkConfig} from "./actions/bookmarks";

import DefaultTheme from "./styles/theme/DefaultTheme";
import AbarthTheme from "./styles/theme/AbarthTheme";
import AlfaRomeoTheme from "./styles/theme/AlfaRomeoTheme";
import AudiTheme from "./styles/theme/AudiTheme";
import BentleyTheme from "./styles/theme/BentleyTheme";
import BmwTheme from "./styles/theme/BmwTheme";
import ChryslerTheme from "./styles/theme/ChryslerTheme";
import CitroenTheme from "./styles/theme/CitroenTheme";
import CupraTheme from "./styles/theme/CupraTheme";
import DaciaTheme from "./styles/theme/DaciaTheme";
import DodgeTheme from "./styles/theme/DodgeTheme";
import DSTheme from "./styles/theme/DSTheme";
import FiatTheme from "./styles/theme/FiatTheme";
import FiatProTheme from "./styles/theme/FiatProTheme";
import FordTheme from "./styles/theme/FordTheme";
import HondaTheme from "./styles/theme/HondaTheme";
import HyundaiTheme from "./styles/theme/HyundaiTheme";
import JaguarTheme from "./styles/theme/JaguarTheme";
import JeepTheme from "./styles/theme/JeepTheme";
import KiaTheme from "./styles/theme/KiaTheme";
import LanciaTheme from "./styles/theme/LanciaTheme";
import LandRoverTheme from "./styles/theme/LandRoverTheme";
import MaseratiTheme from "./styles/theme/MaseratiTheme";
import MazdaTheme from "./styles/theme/MazdaTheme";
import MercedesTheme from "./styles/theme/MercedesTheme";
import MiniTheme from "./styles/theme/MiniTheme";
import MitsubishiTheme from "./styles/theme/MitsubishiTheme";
import OpelTheme from "./styles/theme/OpelTheme";
import PeugeotTheme from "./styles/theme/PeugeotTheme";
import PorscheTheme from "./styles/theme/PorscheTheme";
import SeatTheme from "./styles/theme/SeatTheme";
import SkodaTheme from "./styles/theme/SkodaTheme";
import SmartTheme from "./styles/theme/SmartTheme";
import SubaruTheme from "./styles/theme/SubaruTheme";
import SuzukiTheme from "./styles/theme/SuzukiTheme";
import ToyotaTheme from "./styles/theme/ToyotaTheme";
import VolkswagenTheme from "./styles/theme/VolkswagenTheme";
import VolvoTheme from "./styles/theme/VolvoTheme";
import RenaultTheme from "./styles/theme/RenaultTheme";
import PaypalScriptProvider from "./container/PaypalScriptProvider";
import MgTheme from "./styles/theme/MgTheme";
import BmwmoTheme from "./styles/theme/BmwmoTheme";
import HymerTheme from "./styles/theme/HymerTheme";

export type AppProps = {
	checkConfig: (?boolean, ?string) => boolean,
	loadConfig: ?string => void,
	getBrands: ?string => void,
	config: ConfigReducerState,
	GAID: ?string,
	setCategory: string => void,
	t: translate,
	redirectToBookmarks: boolean,
	resetBookmarksConfig: () => void,
	quickCallProduct: string
}

class App extends Component<AppProps> {
	componentDidMount() {
		if (this.props.config.dealerId) {
			const isSingleShop = !this.props.checkConfig(this.props.config.configLoaded, this.props.config.dealerId, this.props.config.garageGroupId);
			if (isSingleShop) {
				history.pushHome();
			}
		} else {
			this.props.getBrands(this.props.config.garageId || this.props.config.garageGroupId);
		}
	}

	renderTheme() {
		if (this.props.config.configLoaded) {
			switch (this.props.config.theme) {
				case "abarth" :
					return <AbarthTheme>
						{this.renderRouter()}
					</AbarthTheme>;
				case "alfaRomeo" :
				case "alfa romeo" :
					return <AlfaRomeoTheme>
						{this.renderRouter()}
					</AlfaRomeoTheme>;
				case "audi" :
					return <AudiTheme>
						{this.renderRouter()}
					</AudiTheme>;
				case "bentley":
					return <BentleyTheme>
						{this.renderRouter()}
					</BentleyTheme>;
				case "bmw" :
					return <BmwTheme>
						{this.renderRouter()}
					</BmwTheme>;
				case "citroen" :
					return <CitroenTheme>
						{this.renderRouter()}
					</CitroenTheme>;
				case "chrysler" :
					return <ChryslerTheme>
						{this.renderRouter()}
					</ChryslerTheme>;
				case "cupra" :
					return <CupraTheme>
						{this.renderRouter()}
					</CupraTheme>;
				case "dacia" :
					return <DaciaTheme>
						{this.renderRouter()}
					</DaciaTheme>;
				case "dodge" :
					return <DodgeTheme>
						{this.renderRouter()}
					</DodgeTheme>;
				case "ds" :
					return <DSTheme>
						{this.renderRouter()}
					</DSTheme>;
				case "fiat" :
					return <FiatTheme>
						{this.renderRouter()}
					</FiatTheme>;
				case "fiatpro":
					return <FiatProTheme>
						{this.renderRouter()}
					</FiatProTheme>;
				case "ford" :
					return <FordTheme>
						{this.renderRouter()}
					</FordTheme>;
				case "honda" :
					return <HondaTheme>
						{this.renderRouter()}
					</HondaTheme>;
				case "hyundai" :
					return <HyundaiTheme>
						{this.renderRouter()}
					</HyundaiTheme>;
				case "jaguar" :
					return <JaguarTheme>
						{this.renderRouter()}
					</JaguarTheme>;
				case "jeep" :
					return <JeepTheme>
						{this.renderRouter()}
					</JeepTheme>;
				case "kia" :
					return <KiaTheme>
						{this.renderRouter()}
					</KiaTheme>;
				case "lancia" :
					return <LanciaTheme>
						{this.renderRouter()}
					</LanciaTheme>;
				case "landRover" :
				case "land rover" :
					return <LandRoverTheme>
						{this.renderRouter()}
					</LandRoverTheme>;
				case "maserati" :
					return <MaseratiTheme>
						{this.renderRouter()}
					</MaseratiTheme>;
				case "mazda" :
					return <MazdaTheme>
						{this.renderRouter()}
					</MazdaTheme>;
				case "mercedes":
				case "mercedesBenz":
				case "mercedes benz":
				case "MercedesBenz":
					return <MercedesTheme>
						{this.renderRouter()}
					</MercedesTheme>;
				case "mini":
					return <MiniTheme>
						{this.renderRouter()}
					</MiniTheme>;
				case "mitsubishi" :
					return <MitsubishiTheme>
						{this.renderRouter()}
					</MitsubishiTheme>;
				case "opel" :
					return <OpelTheme>
						{this.renderRouter()}
					</OpelTheme>;
				case "peugeot" :
					return <PeugeotTheme>
						{this.renderRouter()}
					</PeugeotTheme>;
				case "porsche" :
					return <PorscheTheme>
						{this.renderRouter()}
					</PorscheTheme>;
				case "renault" :
					return <RenaultTheme>
						{this.renderRouter()}
					</RenaultTheme>;
				case "seat":
					return <SeatTheme>
						{this.renderRouter()}
					</SeatTheme>;
				case "skoda" :
					return <SkodaTheme>
						{this.renderRouter()}
					</SkodaTheme>;
				case "smart":
					return <SmartTheme>
						{this.renderRouter()}
					</SmartTheme>;
				case "subaru":
					return <SubaruTheme>
						{this.renderRouter()}
					</SubaruTheme>;
				case "suzuki":
					return <SuzukiTheme>
						{this.renderRouter()}
					</SuzukiTheme>;
				case "toyota":
					return <ToyotaTheme>
						{this.renderRouter()}
					</ToyotaTheme>;
				case "vw" :
				case "volkswagen" :
					return <VolkswagenTheme>
						{this.renderRouter()}
					</VolkswagenTheme>;
				case "volvo" :
					return <VolvoTheme>
						{this.renderRouter()}
					</VolvoTheme>;
				case "mg" :
					return <MgTheme>
						{this.renderRouter()}
					</MgTheme>;
				case "bmwmo" :
					return <BmwmoTheme>
						{this.renderRouter()}
					</BmwmoTheme>;
				case "hymer" :
					return <HymerTheme>
						{this.renderRouter()}
					</HymerTheme>;
				default:
					return <DefaultTheme>
						{this.renderRouter()}
					</DefaultTheme>;
			}
		} else {
			return <Loading/>;
		}
	}

	renderRouter() {
		if (this.props.redirectToBookmarks) {
			this.props.resetBookmarksConfig();
			history.pushBookmarks();
		}

		if (this.props.quickCallProduct != null) {
			this.props.resetBookmarksConfig();
			history.pushProduct(this.props.quickCallProduct.id, this.props.quickCallProduct.name);
		}

		return <Router history={history}>
			<div lang={this.props.config.moduleLanguage} className={`hpm-${this.props.config.theme}`}>
				<div className={"colorPicker"}/>
				<PaypalScriptProvider/>
				<HeaderContainer/>
				<Switch>
					<Route path={["/brand/:manufacturerName/shop", "/shop"]} component={StartPageContainer}/>
					<Route path={["/brand/:manufacturerName/products/:category1?", "/products/:category2?"]}
						   component={ProductsContainer}/>
					<Route path={["/brand/:manufacturerName/search/:fuzzy1?", "/search/:fuzzy2?"]}
						   component={SearchContainer}/>
					<Route
						path={["/brand/:manufacturerName/product/:articleNumber1?/:name1?", "/product/:articleNumber2?/:name2?"]}
						component={ProductContainer}/>
					{this.renderCheckoutRoute()}
					{this.renderBookmarkRoute()}
					{this.renderDefaultRoute()}
				</Switch>
				<div className="hpm-clear"/>
				<FooterContainer/>
				<div id="hpm-portalRoot"/>
			</div>
		</Router>;
	}

	renderDefaultRoute() {
		if (this.props.config.configLoaded) {
			return <Route exact path={["/", "/brand/:manufacturerName"]} component={StartPageContainer}/>;
		}
		return null;
	}

	renderCheckoutRoute() {
		if (this.props.config.isShopEnabled) {
			return (
				<Route path={["/checkout", "/brand/:manufacturerName/checkout"]} component={CheckoutPageContainer}/>
			);
		}
	}

	renderBookmarkRoute() {
		if (this.props.config.watchlistEnabled) {
			return (
				<Route path={["/bookmarks", "/brand/:manufacturerName/bookmarks"]} component={BookmarksPageContainer}/>
			);
		}
	}

	render() {
		if (this.props.config.configLoaded) {
			return (
				<div className="hpm-App">
					{this.renderTheme()}
				</div>
			);
		}

		if (!this.props.config.garageGroupId && !this.props.config.dealerId) {
			return <Router history={history}>
				<div className={`hpm-${this.props.config.theme}`}>
					<Route
						path={["/brand/:manufacturerName/product/:articleNumber1?/:name1?", "/product/:articleNumber2?/:name2?"]}
						component={loadProductContainer}/>
					<Route path={["/brand/:manufacturerName", "/"]} component={BrandInstanceContainer}/>
				</div>
			</Router>;
		}

		return <Router history={history}>
			<div>
				<Switch>
					<Route
						path={["/brand/:manufacturerName/product/:articleNumber1?/:name1?", "/product/:articleNumber2?/:name2?"]}
						component={loadProductContainer}/>
					<Route path={["/brand/:manufacturerName", "/"]} component={BrandInstanceContainer}/>
				</Switch>
			</div>
		</Router>;
	}
}

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		config: state.config,
		redirectToBookmarks: state.bookmarks.hasQueryParamProducts,
		quickCallProduct: state.bookmarks.quickCallProduct,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		checkConfig: (configLoaded, garageId, garageGroupId) => dispatch(checkConfig(configLoaded, garageId, garageGroupId)),
		getBrands: configId => dispatch(getBrandInstances(configId)),
		resetBookmarksConfig: () => dispatch(resetBookmarkConfig())
	};
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));
