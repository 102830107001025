import React, {useEffect, useState} from "react";
import type {translate} from "../../../i18next";
import "./SpsCouponCode.scss";
import {toast} from "react-toastify";
import type {Coupon, SlyCustomer} from "../../../reducers/couponReducer";
import type {CustomerResponse} from "../../../api/CouponApi";

type SlyCouponCodeProps = {
    t: translate,
    coupon: ?Coupon,
    couponErrorCode: ?string,
    couponSuccessCode: ?string,
    resetCoupon: () => void,
    setSlyCoupon: () => void,
    resetCouponToast: () => void,
    slyCustomer: SlyCustomer,
    slyCustomerData: ?CustomerResponse
};

const SlyCouponCode = ({
                           coupon,
                           couponErrorCode,
                           couponSuccessCode,
                           resetCoupon,
                           resetCouponToast,
                           setSlyCoupon,
                           t,
                           slyCustomerData
                       }: SlyCouponCodeProps) => {
    const [loading, setLoading] = useState(false);
    const [lastActivated, setLastActivated] = useState(() => {
        const saved = localStorage.getItem("lastActivated");
        return saved ? parseInt(saved, 10) : null;
    });
    const [isCouponActive, setIsCouponActive] = useState(() => {
        const saved = localStorage.getItem("isCouponActive");
        return saved === "true";
    });

    const RESET_TIMEOUT = 60 * 1000;

    useEffect(() => {
        if (couponErrorCode) {
            toast.error(t("cart.coupon." + couponErrorCode));
            resetCouponToast();
            setLoading(false);
        }
        if (couponSuccessCode) {
            toast.success(t("cart.coupon." + couponSuccessCode));
            resetCouponToast();
            setLoading(false);
        }
    }, [couponErrorCode, couponSuccessCode, resetCouponToast, t]);

    useEffect(() => {
        if (lastActivated && Date.now() - lastActivated > RESET_TIMEOUT) {
            resetCoupon();
            setIsCouponActive(false);
            localStorage.removeItem("lastActivated");
            localStorage.setItem("isCouponActive", "false");
        }
    }, [RESET_TIMEOUT, lastActivated, resetCoupon]);

    const handleChange = async e => {
        setLoading(true);
        try {
            if (e.target.checked) {
                await setSlyCoupon();
                const now = Date.now();
                setLastActivated(now);
                setIsCouponActive(true);
                localStorage.setItem("lastActivated", now.toString());
                localStorage.setItem("isCouponActive", "true");
            } else {
                await resetCoupon();
                setLastActivated(null);
                setIsCouponActive(false);
                localStorage.removeItem("lastActivated");
                localStorage.setItem("isCouponActive", "false");
            }
        } finally {
            setLoading(false);
        }
    };

    const slyDisabled = !(slyCustomerData && slyCustomerData.availableDeposit.value > 0);

    return (
	<div className={slyDisabled ? "hpm-spsCouponCodeBox disabled" : "hpm-spsCouponCodeBox"}>
		<div className={"accountPointsInfo"}>
			<div className="switch-checkbox">
				<label className="switch">
					{loading ? (
						<div className="loader"></div>
                        ) : (
	<>
		<input
                                    type="checkbox"
                                    checked={isCouponActive}
                                    onChange={handleChange}
                                    disabled={slyDisabled}
                                    className="hpm-couponButton"
                                />
		<span className="sps-checkbox round"></span>
	</>
                        )}
				</label>
				<div className={"coupon-text"}>
					{slyCustomerData && slyCustomerData.availableDeposit.value > 0 ? `${parseFloat(slyCustomerData.availableDeposit.value || 0).toFixed(2).replace(".", ",")} ${t("cart.coupon.slyValue")}` : t("cart.coupon.slyValueZero")}
				</div>
			</div>
			<div className="useCouponText">
				<span>{t("cart.coupon.useCoupon")}</span>
				<span>{t("cart.coupon.willRemain")}</span>
			</div>
		</div>
	</div>
    );
};

export default SlyCouponCode;
