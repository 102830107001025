class StringUtils {
    makeSpacesNonBreaking(input) {
        return input.replace(/ /g, "\u00a0");
    }

    decodeBase64Json(input) {
        let decodedString = null;
        try {
            if (input) {
                decodedString = JSON.parse(atob(input.replace("==>", "")));
            }
        } catch (e) {
            console.error(e);
            decodedString = null;
        }
        return decodedString;
    }

    parseJwt(token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));

        return JSON.parse(jsonPayload);
    }
}

const StringUtilsInstance = new StringUtils();
export default StringUtilsInstance;
