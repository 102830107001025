// @flow
import BrandInstanceStorage from "../helper/storage/BrandInstanceStorage";
import {resetVehicle, setVehicle} from "./vehicle";
import {loadConfig} from "./config";
import type {ApplicationState} from "../reducers";
import ProductApi, {INTENT} from "../api/ProductApi";
import RootNodeProvider from "../helper/RootNodeProvider";
import {RoleTypes} from "../models/Role";
import {
    popularProductsErrorGotten,
    popularProductsRequested,
    popularProductsSuccessGotten, productsErrorGotten, productSet,
    productsRequested,
    productsSet
} from "../reducers/contentReducer";

export const getPopularProducts = () => {
    return (dispatch, getState: () => ApplicationState) => {
        const {filter} = getState().filter;
        const {
            dealerId,
            moduleLanguage,
            role
        } = getState().config;
        const {pickupLocation} = getState().location;
        const {
            vehicle,
            environment
        } = RootNodeProvider.getRootNode().dataset;
        const intent = environment !== "own" ? INTENT.CATALOG_ONLY : INTENT.DEFAULT;
        const productApiNew = new ProductApi(dealerId, {
            configIdType: role,
            intent: intent,
            language: moduleLanguage,
            pickupLocation
        });
        let filterVehicle = null;

        if (vehicle) {
            dispatch(setVehicle(vehicle, null));
            filterVehicle = {
                series: vehicle,
                model: null
            };
            RootNodeProvider.getRootNode().removeAttribute("data-vehicle");
        }

        const filterOptions = {
            filterParameters: {
                categories: filter.categories,
                vehicle: filterVehicle,
                vehicleKey: filterVehicle ? null : filter.vehicleKey,
            },
        };

        const request = productApiNew.getOverview(filterOptions);

        dispatch(popularProductsRequested());

        return request.then(
            response => dispatch(popularProductsSuccessGotten(
                {
                    popularProducts: response.categoryTopProducts,
                    filterStats: response.filterStats,
                    salesProducts: response.markedProducts.find(item => item.category.key === "_SALE")
                })
            ),
            error => {
                console.error(error);
                dispatch(popularProductsErrorGotten(error));
            }
        );
    };
};

export const getProducts = () => {
    return (dispatch, getState: () => ApplicationState) => {
        const {
            dealerId,
            moduleLanguage,
            role
        } = getState().config;
        const {currentPage} = getState().content;
        const {filter} = getState().filter;
        const {pickupLocation} = getState().location;
        const environment = getState().config.environment;
        const intent = environment !== "own" ? INTENT.CATALOG_ONLY : INTENT.DEFAULT;
        const productApi = new ProductApi(dealerId, {
            configIdType: role,
            intent: intent,
            language: moduleLanguage,
            pickupLocation
        });
        const filterOptions = {
            fuzzy: filter.searchPhrase === "undefined" ? "" : filter.searchPhrase,
            filterParameters: {
                intent: null,
                categories: filter.categories,
                categoryKey: filter.categoryKey,
                priceRange: filter.priceRange,
                markers: filter.markers,
                vehicleKey: filter.vehicleKey,
                vehicle: {
                    series: filter.vehicle,
                    model: filter.vehicleModel
                },
            },
            sortOrders: [{
                fieldname: filter.sort.fieldname,
                direction: filter.sort.direction
            }]
        };

        dispatch(productsRequested());
        productApi.getProductsWithFilter(currentPage, filterOptions)
            .then(
                response => dispatch(productsSet({
                    products: response.pagedProducts.productItems,
                    filterStats: response.filterStats,
                    totalPages: response.pagedProducts.totalPages,
                    totalItems: response.pagedProducts.totalItems,
                })),
                error => {
                    console.error(error);
                    dispatch(productsErrorGotten(error));
                }
            );
    };
};

export const getProductOnLoad = (articleNumber, dealerId) => {
    return (dispatch, getState) => {
        const configId = dealerId || getState().config.garageId || getState().config.garageGroupId;
        const {filter} = getState().filter;
        const {
            moduleLanguage,
            role
        } = getState().config;
        const {pickupLocation} = getState().location;
        const filterOptions = {
            vehicle: filter.vehicleKey ? null : {
                series: filter.vehicle,
                model: filter.vehicleModel
            },
            vehicleKey: filter.vehicleKey,
        };

        if (!configId) return;
        const environment = getState().config.environment;
        const intent = environment !== "own" ? INTENT.CATALOG_ONLY : INTENT.DEFAULT;

        const productApi = new ProductApi(configId, {
            configIdType: role,
            intent: intent,
            language: moduleLanguage,
            pickupLocation
        });
        const request = productApi.getProductDetailWithFilter(articleNumber, filterOptions);

        return request.then(
            response => {
                const Storage = new BrandInstanceStorage("garageID");
                Storage.set(response.product.dealerId, response.product.dealerId);

                dispatch(resetVehicle());
                dispatch(loadConfig(response.product.dealerId, role === RoleTypes.GARAGE_GROUP ? RoleTypes.GROUP_DEALER : RoleTypes.DEALER, getState().config.garageGroupId));
            },
            error => {
                console.error(error);
                dispatch(productsErrorGotten(error));
            }
        );
    };
};

export const getProduct = articleNumber => {
    return (dispatch, getState: () => ApplicationState) => {
        const {
            dealerId,
            role,
            moduleLanguage
        } = getState().config;
        const {filter} = getState().filter;
        const {pickupLocation} = getState().location;
        const environment = getState().config.environment;
        const intent = environment !== "own" ? INTENT.CATALOG_ONLY : INTENT.DEFAULT;

        const productApi = new ProductApi(dealerId, {
            configIdType: role,
            intent: intent,
            language: moduleLanguage,
            // eslint-disable-next-line no-restricted-globals
            location,
            pickupLocation
        });
        const filterOptions = {
            vehicle: filter.vehicleKey ? null : {
                series: filter.vehicle,
                model: filter.vehicleModel
            },
            vehicleKey: filter.vehicleKey,
        };

        return productApi.getProductDetailWithFilter(articleNumber, filterOptions)
            .then(response => dispatch(productSet(response)))
            .catch(error => {
                console.error(error);
                dispatch(productsErrorGotten(error));
            });
    };
};
