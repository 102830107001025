import React, {Component} from "react";
import classNames from "classnames";
import Tire from "./TireLabel";
import type {translate} from "../../i18next";
import type {Product} from "../../models/Product";
import type {Contacts} from "../../models/DetailedProduct";
import "./InfoBox.scss";
import ContactInfo from "./ContactInfo";


type InfoBoxProps = {
	t: translate,
	files: Document,
	datas: any,
	product: Product,
	brandName: string,
	contacts: ?Contacts,
}

type InfoBoxState = {
	boxHeight: number,
	currentTab: number,
	oldTab: number,
	tabState: any
}

const TAB_STATE = {
	DESCRIPTION: 0,
	VEHICLE: 1,
	DATA: 2,
	FILES: 3,
	TIRE: 4,
	CONTACTS: 5
};

const MAX_WIDTH = 599;

class InfoBox extends Component<InfoBoxProps, InfoBoxState> {
	static defaultProps = {
		datas: [],
		files: []
	};

	constructor(props) {
		super(props);
		this.state = {
			boxHeight: 0,
			currentTab: TAB_STATE.DESCRIPTION,
			oldTab: TAB_STATE.DESCRIPTION,
			tabState: {[TAB_STATE.DESCRIPTION]: true}
		};
		this.handleResize = this.handleResize.bind(this);
		this.getContentHeight = this.getContentHeight.bind(this);
	}

	checkMobile() {
		return (window.innerWidth < MAX_WIDTH) ? -1 : 0;
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.setState({currentTab: this.checkMobile(), boxHeight: this.getContentHeight(this.state.currentTab)});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		this.setState({currentTab: this.checkMobile(), boxHeight: this.getContentHeight(this.state.currentTab)});
	}

	getContentHeight(tab) {
		switch (tab) {
			case TAB_STATE.DESCRIPTION:
				return document.getElementById("desctab").clientHeight + 50;
			case TAB_STATE.VEHICLE:
				return document.getElementById("vehicletab").clientHeight + 50;
			case TAB_STATE.DATA:
				return document.getElementById("datatab").clientHeight + 50;
			case TAB_STATE.FILES:
				return document.getElementById("filetab").clientHeight + 50;
			case TAB_STATE.TIRE:
				return document.getElementById("tire").clientHeight + 50;
			case TAB_STATE.CONTACTS:
				return document.getElementById("contactstab").clientHeight + 50;
			default:
				return 0;
		}
	}

	selectTab(tab) {
		this.setState({currentTab: tab, oldTab: this.state.currentTab, boxHeight: this.getContentHeight(tab)});
	}

	toggleTab(tab) {
		const tabs = {...this.state.tabState};
		if (tabs[tab]) {
			tabs[tab] = !tabs[tab];
		} else {
			tabs[tab] = true;
		}
		this.setState({tabState: tabs});
	}

	renderFiles() {
		let files = this.props.files || [];
		files = files.map(file => {
			return <li key={file.name}><a href={file.src}>{file.name}</a></li>;
		});
		for (let i = 0; i < 10; i++) {
			files.push(<li ey={`files${i}`} className="hpm-emptyFile"/>);
		}
		return files;
	}

	renderDatas() {
		let datas = this.props.datas || [];
		datas = datas.map(data => {
			return <li key={data.name}><span>{data.name}</span><span>{data.value}</span></li>;
		});
		for (let i = 0; i < 10; i++) {
			datas.push(<li key={`datas${i}`} className="hpm-emptyData"/>);
		}
		return datas;
	}

	renderContacts() {
		return <ContactInfo contacts={this.props.contacts} t={this.props.t} trademark={this.props.product.trademark}/>;
	}

	render() {
		let descTab = classNames("hpm-tab", {
			"hpm-open": (this.state.currentTab === TAB_STATE.DESCRIPTION) || this.state.tabState[TAB_STATE.DESCRIPTION],
			"hpm-slideRight": (this.state.currentTab > TAB_STATE.DESCRIPTION),
			"hpm-slideLeft": (this.state.currentTab < TAB_STATE.DESCRIPTION),
			"hpm-slideRightAfter": (this.state.oldTab > TAB_STATE.DESCRIPTION && this.state.currentTab < TAB_STATE.DESCRIPTION),
			"hpm-slideLeftAfter": (this.state.oldTab < TAB_STATE.DESCRIPTION && this.state.currentTab > TAB_STATE.DESCRIPTION)
		});
		let fileTab = classNames("hpm-tab", {
			"hpm-open": (this.state.currentTab === TAB_STATE.FILES) || this.state.tabState[TAB_STATE.FILES],
			"hpm-slideRight": (this.state.currentTab > TAB_STATE.FILES),
			"hpm-slideLeft": (this.state.currentTab < TAB_STATE.FILES),
			"hpm-slideRightAfter": (this.state.oldTab > TAB_STATE.FILES && this.state.currentTab < TAB_STATE.FILES),
			"hpm-slideLeftAfter": (this.state.oldTab < TAB_STATE.FILES && this.state.currentTab > TAB_STATE.FILES)
		});
		let descNav = classNames("hpm-navElement", {
			"hpm-selected": (this.state.currentTab === TAB_STATE.DESCRIPTION)
		});
		let fileNav = classNames("hpm-navElement", {
			"hpm-selected": (this.state.currentTab === TAB_STATE.FILES)
		});
		let dataTab = classNames("hpm-tab", {
			"hpm-open": (this.state.currentTab === TAB_STATE.DATA) || this.state.tabState[TAB_STATE.DATA],
			"hpm-slideRight": (this.state.currentTab > TAB_STATE.DATA),
			"hpm-slideLeft": (this.state.currentTab < TAB_STATE.DATA),
			"hpm-slideRightAfter": (this.state.oldTab > TAB_STATE.DATA && this.state.currentTab < TAB_STATE.DATA),
			"hpm-slideLeftAfter": (this.state.oldTab < TAB_STATE.DATA && this.state.currentTab > TAB_STATE.DATA)
		});
		let vehicleTab = classNames("hpm-tab", {
			"hpm-open": (this.state.currentTab === TAB_STATE.VEHICLE) || this.state.tabState[TAB_STATE.VEHICLE],
			"hpm-slideRight": (this.state.currentTab > TAB_STATE.VEHICLE),
			"hpm-slideLeft": (this.state.currentTab < TAB_STATE.VEHICLE),
			"hpm-slideRightAfter": (this.state.oldTab > TAB_STATE.VEHICLE && this.state.currentTab < TAB_STATE.VEHICLE),
			"hpm-slideLeftAfter": (this.state.oldTab < TAB_STATE.VEHICLE && this.state.currentTab > TAB_STATE.VEHICLE)
		});
		let dataNav = classNames("hpm-navElement", {
			"hpm-selected": (this.state.currentTab === TAB_STATE.DATA)
		});
		let vehicleNav = classNames("hpm-navElement", {
			"hpm-selected": (this.state.currentTab === TAB_STATE.VEHICLE)
		});
		let tireTab = classNames("hpm-tab", {
			"hpm-open": (this.state.currentTab === TAB_STATE.TIRE) || this.state.tabState[TAB_STATE.TIRE],
			"hpm-slideRight": (this.state.currentTab > TAB_STATE.TIRE),
			"hpm-slideLeft": (this.state.currentTab < TAB_STATE.TIRE),
			"hpm-slideRightAfter": (this.state.oldTab > TAB_STATE.TIRE && this.state.currentTab < TAB_STATE.TIRE),
			"hpm-slideLeftAfter": (this.state.oldTab < TAB_STATE.TIRE && this.state.currentTab > TAB_STATE.TIRE)
		});
		let tireNav = classNames("hpm-navElement", {
			"hpm-selected": (this.state.currentTab === TAB_STATE.TIRE)
		});
		let contactsTab = classNames("hpm-tab", {
			"hpm-open": (this.state.currentTab === TAB_STATE.CONTACTS) || this.state.tabState[TAB_STATE.CONTACTS],
			"hpm-slideRight": (this.state.currentTab > TAB_STATE.CONTACTS),
			"hpm-slideLeft": (this.state.currentTab < TAB_STATE.CONTACTS),
			"hpm-slideRightAfter": (this.state.oldTab > TAB_STATE.CONTACTS && this.state.currentTab < TAB_STATE.CONTACTS),
			"hpm-slideLeftAfter": (this.state.oldTab < TAB_STATE.CONTACTS && this.state.currentTab > TAB_STATE.CONTACTS)
		});
		let contactsNav = classNames("hpm-navElement", {
			"hpm-selected": (this.state.currentTab === TAB_STATE.CONTACTS)
		});

		const navigation = {
			description: this.props.t("navigation.description"),
			data: this.props.t("navigation.data"),
			vehicle: this.props.t("navigation.vehicles"),
			file: this.props.t("navigation.files"),
			tire: this.props.t("navigation.tire"),
			contacts: this.props.t("navigation.contacts")
		};

		return (
			<div className="hpm-articleInfoBox"
								   style={window.innerWidth > 599 ? {height: this.state.boxHeight} : {}}>
				<ul className="hpm-navigation">
					{this.props.product.description &&
					<li className={descNav}
						onClick={() => this.selectTab(TAB_STATE.DESCRIPTION)}>{navigation.description}</li>}
					{this.props.datas.length > 0 &&
					<li className={dataNav} onClick={() => this.selectTab(TAB_STATE.DATA)}>{navigation.data}</li>}
					{this.props.product.fitments.length > 0 &&
					<li className={vehicleNav}
						onClick={() => this.selectTab(TAB_STATE.VEHICLE)}>{navigation.vehicle}</li>}
					{this.props.files.length > 0 &&
					<li className={fileNav} onClick={() => this.selectTab(TAB_STATE.FILES)}>{navigation.file}</li>}
					{this.props.product.tireLabel &&
						<li className={tireNav} onClick={() => this.selectTab(TAB_STATE.TIRE)}>{navigation.tire}</li>}
					<li className={contactsNav} onClick={() => this.selectTab(TAB_STATE.CONTACTS)}>{navigation.contacts}</li>
				</ul>
				<div className={descTab}>
					<div className="hpm-topic"
						 onClick={() => this.toggleTab(TAB_STATE.DESCRIPTION)}>{navigation.description}</div>
					<div id="desctab" className="hpm-tabcontent"
						 dangerouslySetInnerHTML={{__html: this.props.product.description}}/>
				</div>
				{this.props.datas.length > 0 && <div className={dataTab}>
					<div className="hpm-topic" onClick={() => this.toggleTab(TAB_STATE.DATA)}>{navigation.data}</div>
					<div id="datatab" className="hpm-tabcontent hpm-data">
						<ul>
							{this.renderDatas()}
						</ul>
					</div>
				</div>}
				<div className={vehicleTab}>
					<div className="hpm-topic"
						 onClick={() => this.toggleTab(TAB_STATE.VEHICLE)}>{navigation.vehicle}</div>
					<div id="vehicletab" className="hpm-tabcontent hpm-vehicle">
						<ul>
							{this.props.product.fitments.map(vehicle => <li key={vehicle}>{this.props.brandName + " " + vehicle}</li>)}
						</ul>
					</div>
				</div>
				{this.props.files.length > 0 && <div className={fileTab}>
					<div className="hpm-topic" onClick={() => this.toggleTab(TAB_STATE.FILES)}>{navigation.file}</div>
					<div id="filetab" className="hpm-tabcontent hpm-file">
						<ul>
							{this.renderFiles()}
						</ul>
					</div>
				</div>}
				{this.props.product.tireLabel && <div className={tireTab}>
					<div className="hpm-topic" onClick={() => this.toggleTab(TAB_STATE.TIRE)}>{navigation.tire}</div>
					<div id={"tire"} className="hpm-tabcontent hpm-tire">
						<Tire fuelEfficiency={this.props.product.tireLabel.fuelEfficiency}
							  gripOnWetStreet={this.props.product.tireLabel.wetGrip}
							  noiseEmmissionClass={this.props.product.tireLabel.noiseClass}
							  noiseLevel={this.props.product.tireLabel.noise}/>
					</div>
				</div>}
				<div className={contactsTab}>
					<div className="hpm-topic" onClick={() => this.toggleTab(TAB_STATE.CONTACTS)}>{navigation.contacts}</div>
					<div id="contactstab" className="hpm-tabcontent hpm-contacts">
						{this.renderContacts()}
					</div>
				</div>
			</div>
		);
	}
};

export default InfoBox;
