import React from "react";
import "./ContactInfo.scss";

const ContactInfo = ({ contacts, t, trademark }) => {
	if (!contacts) {
		return null;
	}

	const contactOrder = [
		"recipient",
		"recipientLine2",
		"street",
		"streetLine2",
		"location",
		"country",
		"email",
		"phone",
		"web"
	];

	const renderContactDetails = contact => {
		if (!contact) {
			return null;
		}

		const contactDetails = {
			recipient: contact.recipient,
			recipientLine2: contact.recipientLine2,
			street: contact.street,
			streetLine2: contact.streetLine2,
			location: `${contact.state || ""} ${contact.postCode || ""} ${contact.city || ""}`.trim(),
			country: contact.country,
			email: contact.email,
			phone: contact.phone,
			web: contact.website
		};

		return contactOrder
			.filter(key => contactDetails[key])
			.map(key => (
				<div key={key} data-label={key.charAt(0).toUpperCase() + key.slice(1)}>
					{contactDetails[key]}
				</div>
			));
	};

	const hasRepresentativeData =
		contacts.representative && Object.values(contacts.representative).some(value => value);

	const hasProducerData = contacts.producer && Object.values(contacts.producer).some(value => value);

	return (
		<div className="contact-info-tab">
			{hasProducerData && (
				<div>
					<h3>{t("navigation.manufacturer-contact")}</h3>
					{trademark && <div>{t("navigation.trademark")}: {trademark}</div>}
					<div className={"trademark"}>
						{renderContactDetails(contacts.producer)}
					</div>
				</div>
			)}
			{hasRepresentativeData && (
				<div>
					<h3>{t("navigation.responsible-contact")}</h3>
					{renderContactDetails(contacts.representative)}
				</div>
			)}
		</div>
	);

};

export default ContactInfo;
