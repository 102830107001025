// @flow
import type { Axios } from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type Price from "../models/Price";
import type { Coupon } from "../reducers/couponReducer";
import type {HpmPrice} from "../helper/currency";

export type CouponApiOptions = {
	language: ?string,
}

export type CustomerResponse = {
	firstName: string,
	lastName: string,
	street: string,
	postCode: string,
	city: string,
	email: string,
	availableDeposit: Price,
};

export default class CouponApi {
	api: Axios;

	constructor(configId: string, {
		language = navigator.language || "en-GB",
	}: CouponApiOptions = {}) {
		const headers = {
			"X-ModuleType": "HPM",
			"Accept-Language": language + ", *;q0.5",
		};
		this.api = axios.create({
			baseURL: Configuration.value("apiCouponUrl"),
			headers
		});
	}

	checkCouponCode = (code: string = null, dealerNo: string = null, totalOrderValue: HpmPrice = null, totalFullPriceOrderValue: HpmPrice = null, shippingCostValue: HpmPrice = null): Promise<Coupon> => {
		const body = {
			dealerNo: dealerNo,
			couponCode: code,
			financialSummary: {
				totalOrderValue: totalOrderValue.cost,
				totalFullPriceOrderValue: totalFullPriceOrderValue.cost,
				shippingCostValue: shippingCostValue.cost,
				currency: totalOrderValue.currency.code
			}
		};
		return this.api.post("/coupon/validate", body)
			.then(res => res.data);
	};

	checkCouponId = (couponId: string, totalOrderValue: HpmPrice, totalFullPriceOrderValue: HpmPrice, shippingCostValue: HpmPrice): Promise<Coupon> => {
		const body = {
			couponId,
			financialSummary: {
				totalOrderValue: totalOrderValue.cost,
				totalFullPriceOrderValue: totalFullPriceOrderValue.cost,
				shippingCostValue: shippingCostValue.cost,
				currency: totalOrderValue.currency
			}
		};
		return this.api.post("/coupon/validate/id", body)
			.then(res => res.data);
	};

	issueSpsCoupon = (dealerNo: string, spsCustomerId: string, spsDealerId: string): Promise<any> => {
		if (!dealerNo || !spsCustomerId || !spsDealerId) {
			/* eslint-disable-next-line */
			console.log(`DealerNo {}, spsCustomerId {} or spsDealerId {} missing!`, dealerNo, spsCustomerId, spsDealerId);
			return null;
		}
		const body = {
			dealerNo,
			spsCustomerId: spsCustomerId,
			spsDealerId: spsDealerId,
		};
		return this.api.post("/coupon/issue/spscoupon", body)
			.then(res => {
				return res.data;
			});
	};

	issueSlyCoupon = (dealerNo: string, token: string): Promise<any> => {
		if (!dealerNo || !token) {
			/* eslint-disable-next-line */
			console.log(`DealerNo {} or token {} missing!`, dealerNo, token);
			return null;
		}
		const body = {
			dealerNo,
			token,
		};
		return this.api.post("/coupon/issue/slycoupon", body)
			.then(res => {
				return res.data;
			});
	};

	getSlyCustomer = (dealerNo: string, token: string): Promise<CustomerResponse> => {
		if (!dealerNo || !token) {
			return Promise.reject(new Error("DealerNo or Token is missing"));
		}
		const body = {
			dealerNo,
			token,
		};
		return this.api.post("/coupon/sly/customer", body)
			.then(res => res.data);
	};
}
