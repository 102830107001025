// @flow
import {hasTrackersWithoutConsent, loadTrackers, loadTrackersWithoutConsent,} from "../../actions/config";
import type {Tracking} from "../../models/Tracking";
import EVENT_EN from "./Events";
import EVENT_DE from "./EventsDE";
import type {OrderReducerState} from "../../reducers/orderReducer";
import {
    cartSuccessChecked,
    checkoutPageStepSet,
    maximumCheckoutPageStepSet,
    paymentSuccessVerified
} from "../../reducers/orderReducer";
import type {Product} from "../../models/Product";
import StatisticApi from "../../api/StatisticApi";
import type {CouponReducerState} from "../../reducers/couponReducer";
import {
    brandInstanceGotten,
    configSet,
    trackerSuccessLoaded,
    trackerWithoutConsentSuccessLoaded
} from "../../reducers/configReducer";
import {campaignSet, categorySet, searchSet, vehicleSet} from "../../reducers/filterReducer";
import {productSet} from "../../reducers/contentReducer";
import {fromCartRemoved, toCartAdded} from "../../reducers/cartReducer";
import {bookmarkAdded, bookmarkRemoved} from "../../reducers/bookmarksReducer";
import {enquirySaved, shareButtonClicked, wishlistSharingSaved} from "../../reducers/mailingReducer";
import GoogleTracker from "./GoogleTracker";

export const trackers: Tracking[] = [];

export const tracker = store => next => action => {
    try {
        // internal
        const configId = getConfigId(store);
        switch (action.type) {
            case toCartAdded.type: {
                const statisticApi = new StatisticApi(configId);
                statisticApi.trackProductAddedToCart(action.payload.product.id);
                break;
            }
            case bookmarkAdded.type: {
                const statisticApi = new StatisticApi(configId);
                statisticApi.trackAddedToWatchlist(action.payload.id);
                break;
            }
            case enquirySaved.type: {
                const statisticApi = new StatisticApi(configId);
                statisticApi.trackProductQuestion(action.payload.product.id);
                break;
            }
            case shareButtonClicked.type: {
                const statisticApi = new StatisticApi(configId);
                statisticApi.trackProductShared(action.payload.id);
                break;
            }
            default:
                break;
        }

        // third party tracker
        if (store.getState().consent.googleAnalytics || hasTrackersWithoutConsent()) {
            if (store.getState().config.trackerLoaded || store.getState().config.consentlessTrackerLoaded) {
                const EVENT = getTrackerLanguage(store, action).includes("de") ? EVENT_DE : EVENT_EN;
                switch (action.type) {
                    case paymentSuccessVerified.type: {
                        const order: OrderReducerState = store.getState().order;
                        const coupon: CouponReducerState = store.getState().coupon;
                        logOrder(order, coupon);
                        clearCart();
                        break;
                    }
                    case toCartAdded.type: {
                        addItem(EVENT.CATEGORIES.CART, EVENT.ACTIONS.CART.ADD_PRODUCT, action.payload.product);
                        break;
                    }
                    case checkoutPageStepSet.type: {
                        logEvent(EVENT.CATEGORIES.CHECKOUT, "begin_checkout");
                        break;
                    }
                    case cartSuccessChecked.type: {
                        logEvent(EVENT.CATEGORIES.CART, EVENT.ACTIONS.CHECKOUT.VISITED_CART, action.payload);
                        break;
                    }
                    case fromCartRemoved.type: {
                        removeItem(EVENT.CATEGORIES.CART, EVENT.ACTIONS.CART.REMOVE_PRODUCT, action.payload);
                        break;
                    }
                    case bookmarkAdded.type: {
                        insertToWatchlist(EVENT, action.payload);
                        break;
                    }
                    case bookmarkRemoved.type: {
                        removeFromWatchlist(EVENT, action.payload);
                        break;
                    }
                    case configSet.type: {
                        logEvent(EVENT.CATEGORIES.SHOP, EVENT.ACTIONS.SHOP.LOADED);
                        break;
                    }
                    case productSet.type: {
                        viewProductDetails(EVENT, action.payload.product);
                        break;
                    }
                    case searchSet.type: {
                        if (action.payload.searchPhrase) logEvent(EVENT.CATEGORIES.SEARCH, EVENT.ACTIONS.SEARCH.QUERY, action.payload.searchPhrase);
                        trackers.forEach(tracker => {
                            if (tracker instanceof GoogleTracker) {
                                tracker.handleSearchEvent(action.payload.searchPhrase);
                            }
                        });
                        break;
                    }
                    case categorySet.type: {
                        const categoryTitle = action.payload.categories[0] ? action.payload.categories[0].title : EVENT.LABEL.FILTER_RESET;
                        logEvent(EVENT.CATEGORIES.FILTER, EVENT.ACTIONS.FILTER.CATEGORY, categoryTitle);
                        break;
                    }
                    case vehicleSet.type: {
                        logEvent(EVENT.CATEGORIES.FILTER, EVENT.ACTIONS.FILTER.VEHICLE,
                            action.payload.vehicle
                                ? action.payload.vehicle.title
                                : action.payload.vehicleModel ? action.payload.vehicleModel.title : EVENT.LABEL.FILTER_RESET);
                        break;
                    }
                    case campaignSet.type: {
                        logEvent(EVENT.CATEGORIES.FILTER, EVENT.ACTIONS.FILTER.CAMPAIGN, action.payload.campaign || EVENT.LABEL.FILTER_RESET);
                        break;
                    }
                    case enquirySaved.type: {
                        logEvent(EVENT.CATEGORIES.PRODUCT, EVENT.ACTIONS.PRODUCT.PRODUCT_QUESTION, action.payload.product.name);
                        break;
                    }
                    case wishlistSharingSaved.type: {
                        logEvent(EVENT.CATEGORIES.BOOKMARKS, EVENT.ACTIONS.BOOKMARKS.BOOKMARKS_SHARED, `${action.payload.products.length} Produkte`);
                        break;
                    }
                    case shareButtonClicked.type: {
                        logEvent(EVENT.CATEGORIES.PRODUCT, EVENT.ACTIONS.PRODUCT.PRODUCT_SHARED, action.payload.name);
                        trackers.forEach(tracker => {
                            if (tracker instanceof GoogleTracker) {
                                tracker.handleShareEvent(action.payload.name, action.payload.name, action.payload.name);
                            }
                        });
                        break;
                    }
                    case maximumCheckoutPageStepSet.type: {
                        const CHECKOUT_PAGE_STATUS = {
                            CART: 0,
                            ADRESS: 1,
                            PAYMENT: 2,
                            OVERVIEW: 3,
                            FINISH: 4
                        };
                        if (action.payload.step === CHECKOUT_PAGE_STATUS.CART) logEvent(EVENT.CATEGORIES.CHECKOUT, EVENT.ACTIONS.CHECKOUT.VISITED_CART);
                        if (action.payload.step === CHECKOUT_PAGE_STATUS.ADRESS) logEvent(EVENT.CATEGORIES.CHECKOUT, EVENT.ACTIONS.CHECKOUT.ENTERED_CHECKOUT);
                        if (action.payload.step === CHECKOUT_PAGE_STATUS.PAYMENT) logEvent(EVENT.CATEGORIES.CHECKOUT, EVENT.ACTIONS.CHECKOUT.ENTERED_ADDRESS);
                        if (action.payload.step === CHECKOUT_PAGE_STATUS.OVERVIEW) logEvent(EVENT.CATEGORIES.CHECKOUT, EVENT.ACTIONS.CHECKOUT.SELECTED_PAYMENT_METHOD);
                        if (action.payload.step === CHECKOUT_PAGE_STATUS.FINISH) logEvent(EVENT.CATEGORIES.CHECKOUT, EVENT.ACTIONS.CHECKOUT.ORDER_COMPLETE);
                        break;
                    }
                    default:
                        break;
                }
            } else {
                switch (action.type) {
                    case trackerWithoutConsentSuccessLoaded.type:
                    case trackerSuccessLoaded.type:
                    case brandInstanceGotten.type:
                    case configSet.type:
                        break;
                    default:
                        if (store.getState().consent.googleAnalytics && !store.getState().config.trackerLoaded) {
                            store.dispatch(loadTrackers());
                        }
                        if (hasTrackersWithoutConsent() && !store.getState().config.consentlessTrackerLoaded) {
                            store.dispatch(loadTrackersWithoutConsent());
                        }
                        break;
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    return next(action);
};

const getTrackerLanguage = (store, action) => {
    try {
        return action.payload.trackerLanguage || store.getState().config.trackingProperties.trackerLanguage || action.payload.trackingProperties.trackerLanguage || "";
    } catch {
        return "";
    }
};

const getConfigId = store => {
    try {
        return store.getState().config.dealerId || store.getState().config.dealerDetails.cfgKey || store.getState().config.dealerDetails.identifier.dealerNo;
    } catch (e) {
        return "";
    }
};

const logEvent = (cat, action, label) => {
    trackers.forEach(tracker => {
        tracker.logEvent(cat, action, label);
    });
};

const logOrder = (order: OrderReducerState, coupon: ?CouponReducerState) => {
    trackers.forEach(tracker => {
        tracker.logOrder(order, coupon);
    });
};

const addItem = (cat: string, action: string, product: Product) => {
    trackers.forEach(tracker => {
        tracker.addItem(cat, action, product);
    });
};

const removeItem = (cat: string, action: string, product: Product) => {
    trackers.forEach(tracker => {
        tracker.removeItem(cat, action, product);
    });
};

const clearCart = () => {
    trackers.forEach(tracker => {
        tracker.clearCart();
    });
};

const viewProductDetails = (event, product: Product) => {
    trackers.forEach(tracker => {
        tracker.showProductDetails(event, product);
    });
};

const insertToWatchlist = (event, product: Product) => {
    trackers.forEach(tracker => {
        tracker.insertToWatchlist(event, product);
    });
};

const removeFromWatchlist = (event, product: Product) => {
    trackers.forEach(tracker => {
        tracker.removeFromWatchlist(event, product);
    });
};
