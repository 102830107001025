// @flow
import OrderProcessingApi from "../api/OrderProcessingApi";
import type {ApplicationState} from "../reducers";
import type {PaymentMethodResponse} from "../api/PaymentApi";
import PaymentApi from "../api/PaymentApi";
import {toast} from "react-toastify";
import i18n from "../i18next";
import CouponApi from "../api/CouponApi";
import {couponNotValid, getSlyCustomer} from "./coupon";
import type {Coupon} from "../reducers/couponReducer";
import {CouponState} from "../reducers/couponReducer";
import {
	allFromCartRemoved,
	fromCartRemoved,
	paymentMethodsFailureGotten,
	paymentMethodsRequestGotten,
	paymentMethodsSuccessGotten,
	quantitySet,
	toCartAdded
} from "../reducers/cartReducer";
import {
	cartAndCouponSuccessChecked,
	cartFailureChecked,
	cartRequestChecked,
	cartSuccessChecked,
	checkoutPageStepSet,
	maximumCheckoutPageStepSet
} from "../reducers/orderReducer";

export const CHECK_CART_REQUEST = "CHECK_CART_REQUEST";
export const NO_PAYPAL_CONFIGURED = null;
export const SLY_CUSTOMER = "SLY_CUSTOMER";

export const addToCart = (product, shippingMethod) => {
	toast(i18n.t("productInteractions.add"));
	return toCartAdded({
		product,
		shippingMethod
	});
};

export const setPage = (step: number) => checkoutPageStepSet(step);

export const getPaymentMethods = () => {
	return (dispatch, getState: () => ApplicationState) => {
		dispatch(paymentMethodsRequestGotten());
		const paymentApi = new PaymentApi();

		paymentApi.getPaymentMethods(getState().config.dealerId)
			.then((res1: PaymentMethodResponse) => {
				paymentApi.getPaypalClientId(getState().config.dealerId)
					.then(res2 => {
						dispatch(paymentMethodsSuccessGotten({
							paymentMethods: res1.paymentMethods,
							clientId: res2.clientId || NO_PAYPAL_CONFIGURED,
						}));
					}).catch(e => {
					dispatch(paymentMethodsSuccessGotten({
						paymentMethods: res1.paymentMethods,
						clientId: NO_PAYPAL_CONFIGURED,
					}));
				});
			})
			.catch(() => {
				dispatch(paymentMethodsFailureGotten());
			});
	};
};

export const checkCart = () => {
	return (dispatch, getState: () => ApplicationState) => {
		dispatch(cartRequestChecked());
		const orderApi = new OrderProcessingApi();
		const pickupLocation = getState().location.pickupLocation ? {index: getState().location.pickupLocation.cfgKey} : null;
		const couponApi = new CouponApi();
		const myCoupon = (getState().coupon.coupon && getState().coupon.coupon.coupon) ? getState().coupon.coupon.coupon : null;
		const couponId = (myCoupon && myCoupon.couponNo && myCoupon.couponNo.index) ? myCoupon.couponNo.index : null;
		const couponCode = (myCoupon && myCoupon.code) ? myCoupon.code : undefined;
		const dealerId = getState().config.dealerId;
		const token = getState().coupon.slyToken;

		dispatch(getSlyCustomer(dealerId, token));

		const cartValidationRequest = {
			dealerNo: {
				index: getState().config.dealerId
			},
			assembler: pickupLocation,
			items: getState().cart.items.map(item => {
				return {
					...item,
					product: {
						dealerId: getState().config.dealerId,
						productNo: item.product.articleNumber,
						purchaseOrderNo: item.product.id
					},
					shippingMethod: {
						code: item.shippingMethod
					},
					amount: {
						amount: item.amount,
						unit: {
							code: "piece"
						}
					}
				};
			}),
			couponCode,
			paymentProvider: {
				code: "paypal-express"
			},
		};

		const setCouponAndReduceOrderAmount = (couponResponse: Coupon, calcOrderResponse) => {
			dispatch(cartAndCouponSuccessChecked({
				orderItemsPriceSum: calcOrderResponse.orderItems.orderItemsPriceSum,
				orderItemsPriceSumWithShipping: {
					...calcOrderResponse.orderItems.orderItemsPriceSumWithShipping,
					currency: calcOrderResponse.orderItems.orderItemsPriceSumWithShipping.currency.code
				},
				totalBillingAmount: {
					...calcOrderResponse.orderItems.orderItemsPriceSumWithShipping,
					cost: Math.max(0, calcOrderResponse.orderItems.orderItemsPriceSumWithShipping.cost - couponResponse.coupon.eligibleAmount.value)
				},
				coupon: couponResponse,
			}));
		};

		orderApi.calcOrder(cartValidationRequest)
			.then(orderResponse => {
				if (couponId) {
					couponApi
						.checkCouponId(
							couponId,
							{
								value: orderResponse.orderItems.orderItemsPriceSumWithShipping.cost,
								currency: orderResponse.orderItems.orderItemsPriceSumWithShipping.currency.code
							}
						)
						.then(couponResponse => setCouponAndReduceOrderAmount(couponResponse, orderResponse))
						.catch(e => {
							if (e.response && e.response.data && e.response.data.couponState && CouponState.isBelowRequiredAmount(e.response.data.couponState)) {
								setCouponAndReduceOrderAmount(e.response.data, orderResponse);
							} else {
								dispatch(couponNotValid(couponId, e, "error"));
							}
						});
				} else {
					dispatch(cartSuccessChecked({
						totalBillingAmount: orderResponse.orderItems.orderItemsPriceSumWithShipping,
						orderItemsPriceSumWithShipping: orderResponse.orderItems.orderItemsPriceSumWithShipping,
						orderItemsPriceSum: orderResponse.orderItems.orderItemsPriceSum,
					}));
				}
			})
			.catch(() => {
				dispatch(cartFailureChecked());
			});
	};
};

export const setMaxStep = (step: number) => maximumCheckoutPageStepSet(step);

export const removeFromCart = product => {
	toast(i18n.t("productInteractions.remove"));
	return fromCartRemoved(product);
};

export const makeCartEmpty = () => {
	return (dispatch, getState: () => ApplicationState) => {
		if (getState().cart.items.length > 0) {
			toast(i18n.t("productInteractions.clear"));
			dispatch(allFromCartRemoved);
		}
	};
};

export const setQuantity = (id, amount) => quantitySet({
	id: id,
	amount: amount
});
