// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const HymerTheme = props => {
    import("./hymer.lazy.scss");
    return <>
	<ContrastColorUpdater/>
	{props.children}
    </>;
};

export default HymerTheme;
