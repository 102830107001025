// @flow
import OrderProcessingApi from "../api/OrderProcessingApi";
import {ThunkAction} from "@reduxjs/toolkit";
import type {Order, OrderCustomerMail, OrderItemsResponse} from "../models/Order";
import type {PaymentFacilitySessionResponse, PaymentMethod} from "../api/PaymentApi";
import PaymentApi from "../api/PaymentApi";
import type {PayPalPaymentResponse} from "../models/PayPalPaymentResponse";
import type {ApplicationState} from "../reducers";
import {CHECKOUT_PAGE_STATUS} from "../components/checkout/CheckoutPage";
import {PAYMENT_METHODS, PAYMENT_PROVIDER} from "../models/PaymentRequests";
import i18next from "../i18next";

import {
	createOrderFailed,
	createOrderRequested,
	paymentProviderFailureSet,
	paymentProviderRequestSet,
	paymentProviderSuccessSet,
	paypalExpressProviderSuccessSet,
	verifyPaymentFailed
} from "../reducers/cartReducer";
import {
	addressSet,
	checkoutPageStepSet,
	expressPaymentInitiated, orderDetailsCleared, orderStateReset, orderStateSet,
	orderSuccessCreated,
	paymentInitiated,
	paymentSet, paymentSuccessVerified, shopDonePageClosed
} from "../reducers/orderReducer";

export const CREATE_EXPRESS_ORDER = "CREATE_EXPRESS_ORDER";

const orderProcessingApi = new OrderProcessingApi();
const paymentApi = new PaymentApi();

export const prepareOrder = (getState: () => ApplicationState, includeCustomerAddress: boolean): Order => {
	const cartItems = getState().cart.items;
	const pickupLocation = getState().location.pickupLocation ? {index: getState().location.pickupLocation.cfgKey} : null;
	const orderItems = cartItems.map(item => {
		return {
			product: {
				dealerId: getState().config.dealerId,
				productNo: item.product.articleNumber,
				purchaseOrderNo: item.product.id
			},
			amount: {
				amount: item.amount,
				unit: {
					code: "piece"
				}
			},
			shippingMethod: {
				code: item.shippingMethod
			}
		};
	});
	const customerLanguage = getState().config.moduleLanguage || "en";
	const myCoupon = (getState().coupon.coupon && getState().coupon.coupon.coupon) ? getState().coupon.coupon.coupon : null;
	const couponCode = (myCoupon && myCoupon.code) ? myCoupon.code : undefined;

	if (includeCustomerAddress) {
		const customerMail: OrderCustomerMail = {
			mailTo: getState().order.adress.email
		};
		const billingAddress = {
			firstName: getState().order.adress.name,
			name: getState().order.adress.familyname,
			street: getState().order.adress.street,
			city: getState().order.adress.city,
			postalCode: getState().order.adress.postalCode,
			countryCode: getState().order.adress.countryCode
		};
		let shippingAddress = billingAddress;
		if (getState().order.adress.shippingAdress) {
			shippingAddress = {
				firstName: getState().order.adress.shippingName,
				name: getState().order.adress.shippingFamilyname,
				street: getState().order.adress.shippingStreet,
				city: getState().order.adress.shippingCity,
				postalCode: getState().order.adress.shippingPostalCode,
				countryCode: getState().order.adress.shippingCountryCode
			};
		}

		const sps = getState().coupon.sps;
		let comment = getState().order.adress.comment;

		if (sps) {
			comment += `\n\n${i18next.t("cart.coupon.spsCustomerNo")}: ${sps.spsCustomerNo}`;
		}

		const sly = getState().coupon.sly;
		if (sly) {
			comment += `\n\n${i18next.t("cart.coupon.slyCustomerNo")}: ${sly.cardnumber}`;
		}

		return {
			billingAddress,
			customerLanguage,
			customerMail,
			assembler: pickupLocation,
			dealerNo: {
				index: getState().config.dealerId
			},
			shippingAddress,
			items: orderItems,
			couponCode: couponCode,
			customerPhone: getState().order.adress.phone,
			vin: getState().order.adress.vin,
			comment: comment
		};
	}

	return {
		customerLanguage,
		assembler: pickupLocation,
		dealerNo: {
			index: getState().config.dealerId
		},
		items: orderItems,
		couponCode: couponCode,
	};
};

export function createOrder(): ThunkAction {
	return async (dispatch, getState: () => ApplicationState) => {
		dispatch(createOrderRequested());

		const order: Order = prepareOrder(getState, true);
		orderProcessingApi.createOrder(order)
			.then((response: OrderItemsResponse) => {
				dispatch(orderSuccessCreated(response));
			})
			.catch(() => dispatch(createOrderFailed()));
	};
}

// no address needed
export const createExpressOrder = (order: OrderItemsResponse): ThunkAction => {
	return (dispatch, getState: () => ApplicationState) => {
		dispatch({type: CREATE_EXPRESS_ORDER});
		dispatch(orderSuccessCreated(order));
	};
};

export const onPayPalExpressSuccess = (paypalPaymentResponse: PayPalPaymentResponse) => {
	return (dispatch, getState: () => ApplicationState) => {
		dispatch(expressPaymentInitiated());
		const paymentRequest = {
			paymentProvider: PAYMENT_PROVIDER.PAYPAL_EXPRESS,
			paymentMethod: PAYMENT_METHODS.PAYPAL_EXPRESS,
			paymentReference: paypalPaymentResponse
		};
		paymentApi.assignPaymentForOrder(getState().order.order.orderNo,
			{paymentProvider: "PAYPAL_EXPRESS", paymentMethod: "PAYPAL_EXPRESS"}
		)
			.then(assignResponse => {
				dispatch(paymentProviderSuccessSet({
					paymentProvider: (assignResponse.session.paymentProvider || "unset").toLowerCase(),
					paymentMethod: PAYMENT_METHODS.PAYPAL_EXPRESS,
					account: assignResponse.session.account,
				}));
				paymentApi.initiatePayment(getState().order.order.orderNo, paymentRequest)
					.then(() => {
						dispatch(onVerifyPaymentSuccess());
					})
					.catch(() => {
						dispatch(verifyPaymentFailed());
					});
				dispatch(paymentSet({
					paymentProvider: PAYMENT_PROVIDER.PAYPAL_EXPRESS,
					paymentReference: paypalPaymentResponse

				}));
			})
			.catch(err => {
				/* eslint-disable */
				console.log("ERROR ON ASSIGN PAYMENT FOR PAYPAL EXPRESS", err);
				dispatch(verifyPaymentFailed());
			})

	};
};

export const initiatePayment = (paymentProvider = null) => {
	return (dispatch, getState: () => ApplicationState) => {
		dispatch(paymentInitiated());
		const paymentRequest = {
			paymentProvider: paymentProvider || (getState().cart.paymentFacility || {}).paymentProvider,
			paymentReference: (getState().order.payment || {}).paymentReference
		};
		paymentApi.initiatePayment(getState().order.order.orderNo, paymentRequest)
			.then(() => {
				dispatch(onVerifyPaymentSuccess());
			})
			.catch(() => {
				dispatch(verifyPaymentFailed);
			});
	};
};

export const onVerifyPaymentSuccess = () => {
	return dispatch => {
		if (window["gtag"]) {
			const gtagReportConversion = url => {
				const callback = function () {
					if (typeof (url) !== "undefined") {
						window.location = url;
					}
				};
				window["gtag"]("event", "conversion", {
					"send_to": "AW-975834537/PyzrCJLisPsBEKmbqNED",
					"transaction_id": "",
					"value": 1.0,
					"currency": "EUR",
					"event_callback": callback
				});
				return false;
			};
			gtagReportConversion();
		}

		dispatch(checkoutPageStepSet(CHECKOUT_PAGE_STATUS.OVERVIEW));
	};
};

export const getPaymentProvider = (method: PaymentMethod) => {
	return (dispatch, getState: () => ApplicationState) => {
		dispatch(paymentProviderRequestSet);
		const paymentApi = new PaymentApi();

		paymentApi.assignPaymentForOrder(getState().order.order.orderNo, method)
			.then((response: PaymentFacilitySessionResponse) => dispatch(paymentProviderSuccessSet({
				paymentProvider: (response.session.paymentProvider || "unset").toLowerCase(),
				paymentMethod: method.paymentMethod,
				account: response.session.account,
			})))
			.catch(() => {
				dispatch(paymentProviderFailureSet({
					paymentMethod: method.paymentMethod,
				}));
			});
	};
};

export const setPaymentError = () => {
	return dispatch => {
		dispatch(verifyPaymentFailed());
	}
}

export const setAuthorizePaymentProvider = (paymentProvider, paymentReference) => {
	return dispatch => {
		dispatch(paymentSet({
				paymentProvider,
				paymentReference
			}
		));
		dispatch(setCheckoutStep(CHECKOUT_PAGE_STATUS.OVERVIEW));
	};
};

export const setCapturePaymentProvider = (paymentProvider, paymentReference) => {
	return dispatch => {
		dispatch(paymentSet({
				paymentProvider,
				paymentReference
			})
		);
		dispatch(setCheckoutStep(CHECKOUT_PAGE_STATUS.OVERVIEW));
	};
};

export const finishCapturePayment = (paymentReference: string) => {
	return (dispatch, getState: () => ApplicationState) => {
		const orderId = getState().order.order.orderNo;
		paymentApi.finishCapturePayment(orderId, paymentReference)
			.then(res => {
				orderProcessingApi.acceptOrder(orderId)
					.then(dispatch(setCheckoutStep(CHECKOUT_PAGE_STATUS.FINISH)))
			})
			.catch(e => {
				/* eslint-disable-next-line */
				console.log("Error on finishing P24 payment!", e);
				dispatch(verifyPaymentFailed());
			});
	}
};

export const setCheckoutStep = (step: string) => {
	return dispatch => {
		dispatch(checkoutPageStepSet(step));
	};
};

export const acceptOrder = () => {
	return (dispatch, getState: () => ApplicationState) => {
		orderProcessingApi.acceptOrder(getState().order.order.orderNo)
			.then(res =>
				dispatch(paymentSuccessVerified())
			)
			.catch(err => {
				/* eslint-disable */
				console.log("Error on submitting Order!", err);
				dispatch(verifyPaymentFailed());
			});
	};
};

export const getPaypalClientId = () => {
	return (dispatch, getState: () => ApplicationState) => {
		paymentApi.getPaypalClientId(getState().config.dealerId)
			.then(res => {
				dispatch(paypalExpressProviderSuccessSet(res.clientId))
			})
	};
}

export const saveAdress = address => {
	return addressSet(address);
};

export const clearOrderDetails = () => {
	return orderDetailsCleared();
};

export const hideOrderFinished = () => {
	return shopDonePageClosed();
};

export const getOrderState = (orderNo: string) => {
	return dispatch => {
		orderProcessingApi.getOrderState(orderNo)
			.then(response => {
				dispatch(orderStateSet(response));
			});
	};
};

export const resetOrderState = () => {
	return orderStateReset();
};
