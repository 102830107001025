// @flow
import ContrastColorUpdater from "../../helper/ContrastColorUpdater";

const BmwmoTheme = props => {
    import("./bmwmo.lazy.scss");
    return <>
	<ContrastColorUpdater/>
	{props.children}
    </>;
};

export default BmwmoTheme;
