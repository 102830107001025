// @flow

import CouponStorage from "../helper/storage/CouponStorage";
import type Price from "../models/Price";
import {createSlice} from "@reduxjs/toolkit";
import {configSet} from "./configReducer";
import {cartAndCouponSuccessChecked, cartSuccessChecked, orderDetailsCleared} from "./orderReducer";
import {allFromCartRemoved} from "./cartReducer";
import RootNodeProvider from "../helper/RootNodeProvider";
import StringUtils from "../helper/stringUtils";

export const CouponState = {
  VALID: "success",					// all parameters successfully checked
  NOT_FOUND: "notFound", 				// code wrong or out of scope
  NOT_AVAILABLE_YET: "notAvailableYet",		// before time range
  EXPIRED: "expired",				// out of time range
  NOT_ACTIVATED: "notActive",			// disabled
  NO_CONTINGENT: "outOfContingent",			// allowed count of redeems reached
  BELOW_REQUIRED_AMOUNT: "minOrderValue",	// does not reach required order amount

  isBelowRequiredAmount(couponState: string = "") {
    return this[couponState.toUpperCase()] === this.BELOW_REQUIRED_AMOUNT;
  },

  isValid(couponState: string = "") {
    return this[couponState.toUpperCase()] === this.VALID;
  }
};

export type Coupon = {
  coupon: {
    id: string,
    couponNo: {
      index: string,
    },
    code: string,
    displayValue: string,
    displayUnit: string,
    requiredOrderValue: Price,
    eligibleAmount: Price,
    description: ?string,
    validFrom: Date,
    validTo: Date,
  },
  couponState: CouponState.VALID | CouponState.NOT_FOUND | CouponState.NOT_AVAILABLE_YET | CouponState.EXPIRED |
    CouponState.NOT_ACTIVATED | CouponState.NO_CONTINGENT | CouponState.BELOW_REQUIRED_AMOUNT | null,
};

export type DecodedEmbeddedSps = {
  Name: string,
  Vorname: string,
  Strasse: string,
  PLZ: string,
  Ort: string,
  Email: string,
  Guthaben: number,
  DealerID: string,
  KundenID: string,
  Kundennummer: string,
}

export type SpsCustomer = {
  deposit: number,
  spsDealerId: string,
  spsCustomerId: string,
  spsCustomerNo: string,
}

export type DecodedEmbeddedSly = {
    cardnumber: number,
    location: number,
    pointvalue: number,
}

export type SlyCustomer = {
    dealerNo: string,
    token: string,
}

export type CouponReducerState = {
    dealerId: string,
    coupon: Coupon,
    couponErrorCode: string,
    couponSuccessCode: string,
    slyToken: string,
    sps: ?SpsCustomer,
    sly: ?SlyCustomer,
};

let decodedEmbeddedSps: ?DecodedEmbeddedSps = null;
let decodedEmbeddedSly: ?DecodedEmbeddedSly = null;
let embeddedSly: ?string = null;

const getInitialState = () => {
    if (RootNodeProvider.getRootNode() && RootNodeProvider.getRootNode().dataset) {
        const embeddedSps = RootNodeProvider.getRootNode().dataset.embedSps ? RootNodeProvider.getRootNode().dataset.embedSps : null;
        if (embeddedSps) {
            decodedEmbeddedSps = StringUtils.decodeBase64Json(embeddedSps);
        }
        embeddedSly = RootNodeProvider.getRootNode().dataset.embedSmartloyalty ? RootNodeProvider.getRootNode().dataset.embedSmartloyalty : null;
        if (embeddedSly) {
            decodedEmbeddedSly = StringUtils.parseJwt(embeddedSly);
        }
    }

    return {
        dealerId: null,
        coupon: null,
        couponErrorCode: null,
        couponSuccessCode: null,
        slyToken: embeddedSly,
        sps: decodedEmbeddedSps
            ? {
                deposit: decodedEmbeddedSps.Guthaben,
                spsDealerId: decodedEmbeddedSps.DealerID,
                spsCustomerId: decodedEmbeddedSps.KundenID,
                spsCustomerNo: decodedEmbeddedSps.Kundennummer
            }
            : null,
        sly: decodedEmbeddedSly
            ? {
                cardnumber: decodedEmbeddedSly.cardnumber,
                location: decodedEmbeddedSly.location,
                pointvalue: decodedEmbeddedSly.pointvalue,
            }
            : null,
    };
};

const couponSlice = createSlice({
  name: "coupon",
  initialState: getInitialState(),
  reducers: {
    couponSuccessChecked(state, action) {
      const couponStorage = new CouponStorage(state.dealerId);
      couponStorage.set(JSON.stringify(action.payload.coupon));

      state.coupon = action.payload.coupon;
      state.couponErrorCode = null;
      state.couponSuccessCode = CouponState[action.payload.coupon.couponState];
    },
    couponErrorChecked(state, action) {
      const couponStorage = new CouponStorage(state.dealerId);
      couponStorage.clear();

      state.coupon = null;
      state.couponErrorCode = action.payload.errorCode;
    },
    couponReset(state) {
      const couponStorage = new CouponStorage(state.dealerId);
      couponStorage.clear();

      state.coupon = null;
      state.couponErrorCode = null;
      state.couponSuccessCode = null;
    },
    couponToastReset(state) {
      state.couponErrorCode = null;
      state.couponSuccessCode = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(configSet, (state, action) => {
        state.dealerId = action.payload.dealerId;
        state.coupon = action.payload.coupon;
      })
      .addCase(cartAndCouponSuccessChecked, (state, action) => {
        if (action.payload.coupon) {
          const couponStorage = new CouponStorage(state.dealerId);
          couponStorage.set(JSON.stringify(action.payload.coupon));
          state.coupon = action.payload.coupon;
          state.couponErrorCode = null;
        }
      })
      .addCase(allFromCartRemoved, (state, action) => {
        const couponStorage = new CouponStorage(state.dealerId);
        couponStorage.clear();

        state.coupon = null;
        state.couponErrorCode = null;
        state.couponSuccessCode = null;
      })
      .addCase(cartSuccessChecked, (state, action) => {
        if (action.payload.coupon) {
          const couponStorage = new CouponStorage(state.dealerId);
          couponStorage.set(JSON.stringify(action.payload.coupon));

          state.coupon = action.payload.coupon;
          state.couponErrorCode = null;
        }
      })
      .addCase(orderDetailsCleared, state => {
        const couponStorage = new CouponStorage(state.dealerId);
        couponStorage.clear();

        state.coupon = null;
        state.couponErrorCode = null;
        state.couponSuccessCode = null;
      })
    ;
  }
});

export const {
  couponReset,
  couponErrorChecked,
  couponSuccessChecked,
  couponToastReset
} = couponSlice.actions;

export default couponSlice.reducer;
