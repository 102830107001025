import React from "react";
import "./App.scss";
import i18n from "../i18next";
import AudiTheme from "../styles/theme/AudiTheme";
import AlfaRomeoTheme from "../styles/theme/AlfaRomeoTheme";
import VolvoTheme from "../styles/theme/VolvoTheme";
import BmwTheme from "../styles/theme/BmwTheme";
import BentleyTheme from "../styles/theme/BentleyTheme";
import CitroenTheme from "../styles/theme/CitroenTheme";
import FiatTheme from "../styles/theme/FiatTheme";
import FordTheme from "../styles/theme/FordTheme";
import HyundaiTheme from "../styles/theme/HyundaiTheme";
import JaguarTheme from "../styles/theme/JaguarTheme";
import JeepTheme from "../styles/theme/JeepTheme";
import KiaTheme from "../styles/theme/KiaTheme";
import LandRoverTheme from "../styles/theme/LandRoverTheme";
import MitsubishiTheme from "../styles/theme/MitsubishiTheme";
import OpelTheme from "../styles/theme/OpelTheme";
import PeugeotTheme from "../styles/theme/PeugeotTheme";
import SeatTheme from "../styles/theme/SeatTheme";
import SmartTheme from "../styles/theme/SmartTheme";
import SubaruTheme from "../styles/theme/SubaruTheme";
import SkodaTheme from "../styles/theme/SkodaTheme";
import PorscheTheme from "../styles/theme/PorscheTheme";
import VolkswagenTheme from "../styles/theme/VolkswagenTheme";
import MercedesTheme from "../styles/theme/MercedesTheme";
import MiniTheme from "../styles/theme/MiniTheme";
import FiatProTheme from "../styles/theme/FiatProTheme";
import DefaultTheme from "../styles/theme/DefaultTheme";
import RootNodeProvider from "../helper/RootNodeProvider";
import {I18nextProvider} from "react-i18next";
import LangSwitchProvider from "./LangSwitchProvider";
import DealerList from "./NSC/DealerList";
import MgTheme from "../styles/theme/MgTheme";
import HymerTheme from "../styles/theme/HymerTheme";
import BmwmoTheme from "../styles/theme/BmwmoTheme";

type DealerFinderProps = {}

class App extends React.Component<DealerFinderProps> {
	renderTheme(theme, children) {
		switch (theme) {
			case "audi" :
				return <AudiTheme>{children}</AudiTheme>;
			case "alfa romeo" :
				return <AlfaRomeoTheme>{children}</AlfaRomeoTheme>;
			case "volvo" :
				return <VolvoTheme>{children}</VolvoTheme>;
			case "bmw" :
				return <BmwTheme>{children}</BmwTheme>;
			case "bentley":
				return <BentleyTheme>{children}</BentleyTheme>;
			case "citroen" :
				return <CitroenTheme>{children}</CitroenTheme>;
			case "fiat" :
				return <FiatTheme>{children}</FiatTheme>;
			case "ford" :
				return <FordTheme>{children}</FordTheme>;
			case "hyundai" :
				return <HyundaiTheme>{children}</HyundaiTheme>;
			case "jaguar" :
				return <JaguarTheme>{children}</JaguarTheme>;
			case "jeep" :
				return <JeepTheme>{children}</JeepTheme>;
			case "kia" :
				return <KiaTheme>{children}</KiaTheme>;
			case "land rover" :
				return <LandRoverTheme>{children}</LandRoverTheme>;
			case "mitsubishi" :
				return <MitsubishiTheme>{children}</MitsubishiTheme>;
			case "opel" :
				return <OpelTheme>{children}</OpelTheme>;
			case "peugeot" :
				return <PeugeotTheme>{children}</PeugeotTheme>;
			case "seat":
				return <SeatTheme>{children}</SeatTheme>;
			case "smart":
				return <SmartTheme>{children}</SmartTheme>;
			case "subaru":
				return <SubaruTheme>{children}</SubaruTheme>;
			case "skoda" :
				return <SkodaTheme>{children}</SkodaTheme>;
			case "porsche" :
				return <PorscheTheme>{children}</PorscheTheme>;
			case "vw" :
				return <VolkswagenTheme>{children}</VolkswagenTheme>;
			case "mercedes":
				return <MercedesTheme>{children}</MercedesTheme>;
			case "mini":
				return <MiniTheme>{children}</MiniTheme>;
			case "fiatpro":
				return <FiatProTheme>{children}</FiatProTheme>;
			case "mg":
				return <MgTheme>{children}</MgTheme>;
			case "hymer":
				return <HymerTheme>{children}</HymerTheme>;
			case "bmwmo":
				return <BmwmoTheme>{children}</BmwmoTheme>;
			default:
				return <DefaultTheme>{children}</DefaultTheme>;
		}
	}

	render() {
		const dataset = RootNodeProvider.getDealerFinderRootNode().dataset;
		const {theme, dealerId, language, articleNumbers} = dataset;

		return (
			<I18nextProvider i18n={i18n}>
				{this.renderTheme(theme,
					<DealerList dealerNo={dealerId} theme={theme} articleNumbers={(articleNumbers || "").split(",")}/>
				)}
				<LangSwitchProvider language={language}/>
			</I18nextProvider>
		);
	}
}

export default App;
